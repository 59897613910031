<app-navbar></app-navbar>

<app-page-layout *ngIf="!(loading | async);else placeholder">
  <div class="page-title">
    <p><fa-icon id="go-back-btn" data-cy="go-back-btn" [icon]="['fal','square-chevron-left']"
        class="cursor-pointer mr-3" (click)="goToSearch()"></fa-icon>
      View Car</p>
  </div>

  <div class="grid grid-cols-1 xl:grid-cols-2 gap-6">
    <!-- main info -->

    <!-- left info -->
    <div class="col-span-1 flex flex-col gap-6">
      <!-- photos -->
      <app-main-content-layout>
        <app-image-slider [images]="carPhotos"></app-image-slider>
      </app-main-content-layout>

      <!-- Specifications -->
      <app-main-content-layout>
        <div class="title">
          <p>Specifications</p>
        </div>

        <div class="flex flex-col gap-6">
          <div class="flex justify-between">
            <p>Make:</p>
            <p>{{car!.make}}</p>
          </div>
          <div class="flex justify-between">
            <p>Model:</p>
            <p>{{car!.model}}</p>
          </div>
          <div class="flex justify-between">
            <p>Variant:</p>
            <p>{{car!.variant}}</p>
          </div>
          <div class="flex justify-between" *ngIf="car!.trim">
            <p>Trim:</p>
            <p>{{car!.trim}}</p>
          </div>
          <div class="flex justify-between">
            <p>Model year:</p>
            <p>{{car!.manufactureYear}}</p>
          </div>
          <div class="flex justify-between">
            <p>First registration:</p>
            <p>{{car!.firstReg}}</p>
          </div>
          <div class="flex justify-between">
            <p>Mileage:</p>
            <p>{{car!.mileage | number}} km</p>
          </div>
          <div class="flex justify-between">
            <p>Body type:</p>
            <p>{{car!.bodyType}}</p>
          </div>
          <div class="flex justify-between">
            <p>Driving wheels:</p>
            <p>{{car!.drivingWheels}}</p>
          </div>
          <div class="flex justify-between">
            <p>Engine power:</p>
            <p>{{car!.enginePower}}</p>
          </div>
          <div class="flex justify-between">
            <p>Engine capacity:</p>
            <p>{{car!.engineVolume}}</p>
          </div>
          <div class="flex justify-between">
            <p>Fuel type:</p>
            <p>{{car!.fuelType}}</p>
          </div>
          <div class="flex justify-between">
            <p>Gearbox:</p>
            <p>{{car!.gearbox}}</p>
          </div>
          <div class="flex justify-between">
            <p>CO2 NEDC:</p>
            <p>{{car!.co2NEDC ? (car!.co2NEDC | number) : 'NaN'}} g/km</p>
          </div>
          <div class="flex justify-between">
            <p>CO2 WLTP:</p>
            <p>{{car!.co2WLTP ? (car!.co2WLTP | number) : 'NaN'}} g/km</p>
          </div>
          <div class="flex justify-between">
            <p>Color:</p>
            <p>{{car!.color}}</p>
          </div>
        </div>
      </app-main-content-layout>

      <!-- equipment -->
      <app-main-content-layout *ngIf="standardEquipments.length > 0">
        <div class="title">
          <p>Standard equipment</p>
        </div>

        <div class="grid grid-cols-2 gap-y-3">
          <div class="col-span-1 flex gap-3 cursor-default" *ngFor="let eq of standardEquipments">
            <fa-icon [icon]="['fal','circle-check']"></fa-icon>
            <p>{{eq}}</p>
          </div>
        </div>
      </app-main-content-layout>

      <!-- equipment -->
      <app-main-content-layout *ngIf="equipments.length > 0">
        <div class="title">
          <p>Optional equipment</p>
        </div>

        <div class="grid grid-cols-2 gap-y-3">
          <div class="col-span-1 flex gap-3 cursor-default" *ngFor="let eq of equipments">
            <fa-icon [icon]="['fal','circle-check']"></fa-icon>
            <p>{{eq}}</p>
          </div>
        </div>
      </app-main-content-layout>

      <!-- other info -->
      <app-main-content-layout>
        <div class="title">
          <p>Other info</p>
        </div>

        <div class="flex flex-col gap-6">
          <div class="flex justify-between">
            <p>Location:</p>
            <p>{{car!.locationName}}</p>
          </div>
          <div class="flex justify-between">
            <p>Damages:</p>
            <p>€{{car!.damages ? car!.damages : 0 | number}}</p>
          </div>
          <div class="flex justify-between">
            <p>VIN:</p>
            <p>{{car!.vin ? car!.vin : '-'}}</p>
          </div>

          <div *ngIf="car!.otherInfo && car!.otherInfo.length > 0" class="p-4 border border-white-600 rounded">
            <p>{{car!.otherInfo}}</p>
          </div>
        </div>
      </app-main-content-layout>

      <!-- car documents -->
      <app-main-content-layout *ngIf="car!.documents.length > 0">
        <div class="title">
          <p>Documents</p>
        </div>

        <div class="flex flex-wrap gap-4">
          <div *ngFor="let d of car!.documents" class="bg-white-700 rounded px-9 py-3 flex gap-4 w-fit items-center">
            <p class="whitespace-nowrap overflow-hidden text-ellipsis max-w-sm">{{d.name}}</p>

            <p class="underline text-blue-500 cursor-pointer active:scale-95 text-sm" (click)="downloadDoc(d.id)">
              download document
            </p>
          </div>
        </div>
      </app-main-content-layout>
    </div>

    <!-- right info -->
    <div class="col-span-1 flex flex-col gap-6">
      <!-- base data -->
      <app-main-content-layout>
        <div class="flex flex-col gap-9">
          <div class="flex flex-col gap-3">
            <p class="font-bold text-xl">{{car!.make}}, {{car!.model}}</p>

            <p *ngIf="isAdmin && car!.carStatus != 'Sold'" id="edit-car-btn" data-cy="edit-car-btn"
              class="cursor-pointer" (click)="goToCar()">
              <fa-icon [icon]="['fal','pen']" class="mr-3"></fa-icon> Edit
              car
            </p>
          </div>

          <!-- <div class="flex justify-between mt-3 gap-3">
            <app-dropdown id="delivery-country" [label]="'Change delivery country'" [options]="countries"
              [control]="deliveryCountryControl" (emitOnChangeSelect)="changeDeliveryCountry($event)">
            </app-dropdown>

            <div class="flex gap-3">
              <secondary-btn id="select-to-promote" btnText="Select to promote">
              </secondary-btn>

              <primary-btn id="add-to-buy" [blueBtn]="true" btnText="Add to buy list" (clickEmitter)="addToCart()">
              </primary-btn>
            </div>
          </div> -->

          <div class="flex justify-between items-center">
            <div>
              <div class="flex items-baseline gap-2">
                <p class="font-bold text-red text-2xl">€{{car!.sellingPrice! | number}}</p>
                <p class="text-sm text-white-600">{{car!.vatStatus ? vats.ExVAT : vats.InclVAT}}, price does not include
                  transport</p>
              </div>
              <p class="text-xs font-light text-white-600">Min. selling price €{{car!.minSellingPrice | number}}</p>
            </div>

            <!-- <div class="flex gap-3">
              <fa-icon [icon]="['fal','cube']"></fa-icon>
              <p>Delivered to: <span class="font-bold">{{deliverToCountry}}</span></p>
            </div> -->
          </div>

          <div class="flex flex-col gap-6 pt-9 border-t border-white-500">
            <div class="flex justify-between">
              <p>Seller name:</p>
              <p>{{car!.sellerName}}</p>
            </div>
            <div class="flex justify-between">
              <p>Vehicles location:</p>
              <p>{{car!.locationName}}</p>
            </div>
            <div class="flex justify-between">
              <p>KAM seller:</p>
              <p>{{car!.kamName}}</p>
            </div>
          </div>

          <div class="flex flex-col gap-6 pt-9 border-t border-white-500">
            <div class="flex justify-between">
              <p>Buying price:</p>
              <p class="font-bold">€{{car!.buyingPrice | number}}</p>
            </div>
            <div class="flex justify-between">
              <p>EST Profit:</p>
              <!-- <p class="font-bold text-green-200" [ngClass]="{'!text-red': !bcDeliveryCountry || (bcDeliveryCountry && bcDeliveryCountry.spotPrice -
              car.sellingPrice! < 0) }">{{bcDeliveryCountry ? '€' + (bcDeliveryCountry.spotPrice -
                car.sellingPrice! | number) : 'Not determined'}}</p> -->

              <p class="font-bold text-green-200"
                [ngClass]="{'!text-red' : (car!.sellingPrice! - car!.buyingPrice! )< 0}">€{{car!.sellingPrice! -
                car!.buyingPrice! | number}}</p>
            </div>
            <div class="text-sm text-white-300">
              <p>Date added: {{car!.created_at * 1000 | date: 'dd/MM/yyyy'}}</p>
            </div>
          </div>
        </div>
      </app-main-content-layout>

      <!-- journeys -->
      <app-main-content-layout>
        <div class="title">
          <p>Journeys & price settings</p>
        </div>

        <div class="flex flex-col gap-6 ">
          <app-main-content-row-layout>
            <p>Status</p>

            <app-read-only-input class="secondContent">{{car!.carStatus}}</app-read-only-input>
          </app-main-content-row-layout>


          <app-main-content-row-layout>
            <p>Buying price</p>

            <app-read-only-input class="secondContent">€{{car!.buyingPrice| number}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Source</p>

            <app-read-only-input class="secondContent">{{car!.sourceName}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Inactive from</p>

            <app-read-only-input class="secondContent">{{car!.inactiveFrom}}</app-read-only-input>
          </app-main-content-row-layout>
        </div>

        <div *ngFor="let j of journeys; let i = index" class="flex flex-col gap-6 pt-6 border-t border-white-500">
          <app-main-content-row-layout>
            <p class="font-bold">Journey {{i+1}}</p>

            <app-read-only-input class="secondContent">{{getJourneyName(j)}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Journey selling price</p>

            <app-read-only-input class="secondContent">€{{j.sellingPrice | number}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>Start date</p>

            <app-read-only-input class="secondContent">{{j.dateFrom}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p>End date</p>

            <app-read-only-input class="secondContent">{{j.dateTo}}</app-read-only-input>
          </app-main-content-row-layout>
        </div>
      </app-main-content-layout>
    </div>

    <!-- bottom side info -->
    <div class="col-span-1 xl:col-span-2 pt-6 border-t border-white-500 grid grid-cols-1 xl:grid-cols-2 gap-6">
      <!-- market data -->
      <app-main-content-layout>
        <div class="title">
          <p>Market data</p>
        </div>

        <app-table *ngIf="car!.businessCase.length > 0" [headersMap]="bcTableHeaderMap"
          [displayedColumns]="bcDisplayedColumns" [data]="car!.businessCase" [hidePaginator]="true"></app-table>

      </app-main-content-layout>

      <!-- transport matrix -->
      <app-main-content-layout>
        <div class="title">
          <p>Transport matrix</p>
        </div>

        <app-main-content-row-layout>
          <p>Origin country</p>

          <app-read-only-input class="secondContent">{{car!.locationName}}</app-read-only-input>
        </app-main-content-row-layout>


        <app-table [headersMap]="tmTableHeaderMap" [displayedColumns]="tmDisplayedColumns" [data]="car!.transportMatrix"
          [hidePaginator]="true"></app-table>
      </app-main-content-layout>
    </div>
  </div>
</app-page-layout>


<ng-template #placeholder>
  <div class="grid grid-cols-1 xl:grid-cols-2 px-12 py-9 w-full gap-6">
    <div class="col-span-1 flex justify-center">
      <img class="rounded object-none opacity-30" src="./assets/background-photos/car-placeholder.png" alt="">
    </div>
    <app-main-content-placeholder></app-main-content-placeholder>
    <app-main-content-placeholder></app-main-content-placeholder>
    <app-main-content-placeholder></app-main-content-placeholder>
  </div>
</ng-template>
