<div class="twoCols">
  <div class="col-span-2 flex justify-between">
    <app-dropdown id="stores" [options]="storesDropdown" label="Store" [control]="storeToggleControl"
      (emitOnChangeSelect)="changeStore($event)"></app-dropdown>

    <div class="flex gap-6">
      <secondary-btn id="save-store" btnText="Save Store" (clickEmitter)="saveStore()"></secondary-btn>

      <fa-icon class="text-2xl border rounded px-4 cursor-pointer active:scale-95 py-1" [matMenuTriggerFor]="moreBtns"
        [icon]="['fal','ellipsis-vertical']"></fa-icon>

      <mat-menu #moreBtns="matMenu">
        <button mat-menu-item (click)="addNewStore()">Add new store</button>

        <button mat-menu-item (click)="copyDataFromCompany()">Copy data from main company</button>

        <button mat-menu-item (click)="copyMakes()">Copy makes from main company</button>
      </mat-menu>
    </div>
  </div>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Store details</p>
    </div>

    <app-main-content-row-layout>
      <p>Store name*</p>
      <app-input id="store-name" placeholder="Insert store name" class="secondContent"
        [control]="storeDetailsForm.controls.name"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Address*</p>
      <app-input id="address" placeholder="Insert address" class="secondContent"
        [control]="storeDetailsForm.controls.address"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Region*</p>
      <div class="secondContent twoCols">
        <app-read-only-input>{{companyCountry.name}}</app-read-only-input>

        <app-input id="city" placeholder="Insert city" class="col-span-1"
          [control]="storeDetailsForm.controls.city"></app-input>
      </div>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>ZIP Code*</p>
      <app-input id="postalcode" placeholder="Insert zip code" class="secondContent"
        [control]="storeDetailsForm.controls.postalCode"></app-input>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Use country’s bank holidays</p>

      <app-toggle id="country-holidays" class="secondContent"
        [toggleFormControl]="storeDetailsForm.controls.countryBank"></app-toggle>
    </app-main-content-row-layout>

    <app-main-content-row-dropdown title="Makes sold" componentId="makes-sold" placeholder="Select makes sold"
      [control]="storeDetailsForm.controls.makesSold" [multipleSelect]="true" [options]="makes"
      [selectAll]="true"></app-main-content-row-dropdown>

    <app-main-content-row-dropdown title="Makes represented" componentId="makes-represented"
      placeholder="Select makes represented" [control]="storeDetailsForm.controls.makesRep" [multipleSelect]="true"
      [options]="makes" [selectAll]="true"></app-main-content-row-dropdown>
  </app-main-content-layout>

  <app-main-content-layout class="col-span-1">
    <div class="title">
      <p>Store info</p>
    </div>

    <app-main-content-row-layout>
      <p>Status</p>
      <app-toggle id="status" class="secondContent" [toggleFormControl]="statusControl"></app-toggle>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Date created</p>

      <app-read-only-input class="secondContent">
        {{dateCreated | date:'dd/MM/yyyy'}}
      </app-read-only-input>
    </app-main-content-row-layout>
  </app-main-content-layout>
</div>
