import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CreateBuyerUserBody, EditBuyerUserBody } from 'src/app/core/models/buyer.model';
import { BuyerUser, UserRoles } from 'src/app/core/models/user.model';
import { BuyerSyncService } from 'src/app/core/services/buyer-flow-sync.service';
import { BuyerService } from 'src/app/core/services/buyer.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';

interface UserStore {
  id: string,
  contactFlag: boolean,
  contactRoleDetails: {
    accEmailFlag: boolean,
    carDeliveryEmailFlag: boolean,
    documentEmailFlag: boolean,
  }
}

@Component({
  selector: 'app-view-buyer-users',
  templateUrl: './view-buyer-users.component.html',
  styleUrls: ['./view-buyer-users.component.scss']
})
export class ViewBuyerUsersComponent implements OnInit {
  createMode: boolean = false;
  buyerId = this.buyerSyncService.buyerId;

  @Input() isAdmin = false;
  editMode = false;

  subscriptions: Subscription = new Subscription();

  dateCreated = new Date();
  dateUpdated = new Date();

  statusControl = new FormControl({ value: false, disabled: true });

  userDetailsForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    countryId: ['', Validators.required],
    phoneNumber: [''],
    closedSalesGroups: [['']]
  });

  stores: DropdownOption[] = this.buyerSyncService.stores.map(s => ({ viewValue: s.name!, value: s.id }));
  storeControl = new FormControl<string[]>([''], Validators.required);

  selectedStoreControl = new FormControl();
  storesSelected: DropdownOption[] = [];

  userDropdown: DropdownOption[] = this.buyerSyncService.buyerGetResponse!.users.map(u => ({ viewValue: `${u.firstName} ${u.lastName}`, value: u.id }));
  // userDropdown: DropdownOption[] = [];
  userControl = new FormControl();

  countries = this.coreService.countriesDropdown;
  groups = this.buyerSyncService.groups;

  userEmail = '';
  userEmailControl = new FormControl('', [Validators.required, Validators.email]);

  isContact = false;
  contactForm = this.fb.group({
    accountingEmail: new FormControl<boolean>(false),
    carDeliveryEmail: new FormControl<boolean>(false),
    documentsEmail: new FormControl<boolean>(false),
  });

  usersStoresMap: Map<string, UserStore[]> = new Map();

  constructor(private buyerSyncService: BuyerSyncService,
    private buyerService: BuyerService,
    private coreService: CoreService,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private spinner: SpinnerHandlerService) { }

  ngOnInit() {
    this.subscriptions.add(this.buyerSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.buyerSyncService.setCurrentTab(value);
      }
    ));

    this.subscriptions.add(this.buyerSyncService.saveFormEvent$.subscribe((value) => {
      this.saveUser();
    }));

    this.buyerSyncService.buyerGetResponse!.users.forEach(u => {
      this.usersStoresMap.set(u.id, u.details.buyerStores.map(s => ({
        id: s.store,
        contactFlag: s.roles.includes('contact'),
        contactRoleDetails: s.contactRoleDetails
      })));
    });

    if (this.userDropdown.length > 0) {
      this.userControl.setValue(this.userDropdown[0].value);
      this.patchUserForm(this.buyerSyncService.buyerGetResponse!.users.find(u => u.id === this.userDropdown[0].value)!);
    }

    this.userDetailsForm.disable();
    this.contactForm.disable();
    this.storeControl.disable();
    this.selectedStoreControl.disable();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveUser() {
    if (this.spinner.showProgressBar.value) return;

    if (this.userDetailsForm.invalid || this.storeControl.invalid) {
      this.userDetailsForm.markAllAsTouched();

      this.snackbar.negativeSentiment('Complete all requried fields');

      return;
    }

    this.spinner.showProgressBar.next(true);

    let stores = this.usersStoresMap.get(this.userControl.value!)!;

    if (this.selectedStoreControl.value) {
      stores[stores.findIndex(s => s.id === this.selectedStoreControl.value!)] = {
        id: this.selectedStoreControl.value!,
        contactFlag: this.isContact,
        contactRoleDetails: {
          accEmailFlag: this.contactForm.controls.accountingEmail.value!,
          carDeliveryEmailFlag: this.contactForm.controls.carDeliveryEmail.value!,
          documentEmailFlag: this.contactForm.controls.documentsEmail.value!,
        }
      }
    }

    if (this.createMode) {
      if (this.userEmailControl.invalid) {
        this.userEmailControl.markAllAsTouched();

        this.snackbar.negativeSentiment('Complete all requried fields');

        return;
      }

      let body = new CreateBuyerUserBody(this.buyerId!, this.userDetailsForm.value, stores, this.userEmailControl.value!);

      this.buyerService.createBuyerUser(body).subscribe({
        next: resp => {
          this.buyerSyncService.buyerGetResponse!.users.push({
            email: body.email,
            firstName: body.firstName,
            lastName: body.lastName,
            username: body.email,
            phoneNumber: body.phoneNumber,
            isActive: true,
            details: {
              buyerCompany: this.buyerId!,
              buyerStores: stores.map(s => ({
                store: s.id,
                roles: s.contactFlag ? ['user', 'contact'] : ['user'],
                contactRoleDetails: s.contactRoleDetails
              })),
              closedSalesGroups: body.closedSalesGroups,
            },
            id: resp,
            createdAt: new Date().getTime(),
            modifiedAt: new Date().getTime(),
            countryId: body.countryId,
          });

          this.userDropdown = this.buyerSyncService.buyerGetResponse!.users.map(u => ({ viewValue: `${u.firstName} ${u.lastName}`, value: u.id }));

          this.userControl.setValue(resp);

          this.usersStoresMap.delete('newUser');

          this.usersStoresMap.set(resp, stores);

          this.userEmail = body.email;

          this.createMode = false;

          this.spinner.showProgressBar.next(false);

          this.snackbar.positiveSentiment('User created');
        },
        error: err => {
          this.spinner.showProgressBar.next(false);

     this.snackbar.negativeSentiment(err.error.error ? err.error.error : err.error);
        }
      });
    } else {
      let body = new EditBuyerUserBody(this.buyerId!, this.userControl.value!, this.userDetailsForm.value, stores, this.statusControl.value!);

      this.editUser(body);
    }
  }

  changeUserStatus() {
    let user = this.buyerSyncService.buyerGetResponse!.users.find(u => u.id === this.userControl.value!)!;

    let stores = user.details.buyerStores.map(s => ({
      id: s.store,
      contactFlag: s.roles.includes(UserRoles.Contact),
      contactRoleDetails: s.contactRoleDetails
    }));

    let body = new EditBuyerUserBody(this.buyerId!, this.userControl.value!, user, stores, !this.statusControl.value!);

    this.spinner.showProgressBar.next(true);

    this.editUser(body);
  }

  editUser(body: EditBuyerUserBody) {
    this.buyerService.editBuyerUser(body).subscribe({
      next: resp => {
        let user = this.buyerSyncService.buyerGetResponse!.users.find(c => c.id === body.id)!;

        user = {
          ...user,
          ...body,
          details: {
            ...user.details,
            closedSalesGroups: body.closedSalesGroups,
            buyerStores: body.stores.map(s => ({
              store: s.id,
              roles: s.contactFlag ? ['user', 'contact'] : ['user'],
              contactRoleDetails: s.contactRoleDetails
            }))
          }
        };

        this.statusControl.setValue(body.isActive);

        this.buyerSyncService.buyerGetResponse!.users[this.buyerSyncService.buyerGetResponse!.users.findIndex(u => u.id === user.id)] = user;

        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment(resp);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error.error ? err.error.error : err.error);
      }
    });
  }

  changeUser(userId: string) {
    if (this.createMode) {
      const currentUserId = this.userControl.value;

      const dialogRef = this.dialog.open(
        UnsavedChangesModalComponent, {
        width: '650px',
        autoFocus: false,
        data: {}
      });

      dialogRef.afterClosed().subscribe(resp => {
        if (resp) {
          if (currentUserId) this.userDropdown = this.buyerSyncService.buyerGetResponse!.users.map(c => ({ viewValue: `${c.firstName} ${c.lastName}`, value: c.id }));

          this.continueChangeUserFlow(userId);
        } else {
          this.userControl.setValue(currentUserId);
        }
      })
    } else {
      this.continueChangeUserFlow(userId);
    }
  }

  continueChangeUserFlow(userId: string) {
    let user = this.buyerSyncService.buyerGetResponse!.users.find(c => c.id === userId);

    this.patchUserForm(user!);
  }

  patchUserForm(user: BuyerUser) {
    this.userDetailsForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      countryId: user.countryId,
      closedSalesGroups: user.details.closedSalesGroups
    });

    this.userEmail = user.email;
    this.statusControl.setValue(user.isActive);

    let userStores = this.usersStoresMap.get(user.id);

    this.dateCreated = new Date(user.createdAt * 1000);
    this.dateUpdated = new Date(user.modifiedAt * 1000);

    if (userStores!.length > 0) {
      this.selectedStoreControl.setValue(userStores![0].id);

      this.patchContactForm(userStores![0]);
    }

    this.storeControl.setValue(user.details.buyerStores!.map(s => s.store));
    this.storesSelected = this.stores.filter(s => this.storeControl.value!.includes(s.value));

    this.createMode = false;
  }

  addUser() {
    if (this.createMode) return this.snackbar.negativeSentiment('You already started to create a new contact!');

    this.createMode = true;

    this.userDropdown.push({ value: 'newUser', viewValue: 'New User' });

    this.userDetailsForm.reset({
      closedSalesGroups: []
    });
    this.storeControl.reset();
    this.selectedStoreControl.reset();
    this.isContact = false;

    this.storesSelected = [];

    this.userEmailControl.reset();

    this.usersStoresMap.set('newUser', []);
    this.userControl.setValue('newUser');

    this.contactForm.reset({
      accountingEmail: false,
      documentsEmail: false,
      carDeliveryEmail: false
    });
  }

  enterEditMode() {
    this.editMode = true;
    this.userDetailsForm.enable();
    this.contactForm.enable();
    this.storeControl.enable();
    this.selectedStoreControl.enable();

    if (this.userDropdown.length === 0) this.addUser();
  }

  changeStoresSelected() {
    setTimeout(() => {
      this.storesSelected = this.stores.filter(s => this.storeControl.value!.includes(s.value));

      if (this.selectedStoreControl.value && this.storesSelected.length > 0 && !this.storesSelected.find(s => s.value === this.selectedStoreControl.value!)) {
        this.selectedStoreControl.setValue(this.storesSelected[0].value);

        this.patchContactForm(this.usersStoresMap.get(this.userControl.value!)!.find(s => s.id === this.storesSelected[0].value)!);
      } else if (this.selectedStoreControl.value && !this.storesSelected.find(s => s.value === this.selectedStoreControl.value!)) {
        this.isContact = false;

        this.contactForm.reset({
          accountingEmail: false,
          documentsEmail: false,
          carDeliveryEmail: false
        });
      }

      let oldStores = this.usersStoresMap.get(this.userControl.value!)!;
      let newStores: UserStore[] = [];

      this.storesSelected.forEach(s => {
        let store = oldStores.find(ss => s.value === ss.id);

        if (store) {
          newStores.push(store);
        } else {
          newStores.push({
            id: s.value,
            contactFlag: false,
            contactRoleDetails: {
              accEmailFlag: false,
              documentEmailFlag: false,
              carDeliveryEmailFlag: false
            }
          });
        }

        this.usersStoresMap.set(this.userControl.value!, newStores);
      });
    }, 0);
  }

  patchContactForm(store: UserStore) {
    this.isContact = store.contactFlag;

    if (!store.contactRoleDetails) {
      this.contactForm.patchValue({
        accountingEmail: false,
        carDeliveryEmail: false,
        documentsEmail: false,
      });
    } else {
      this.contactForm.patchValue({
        accountingEmail: store.contactFlag ? store.contactRoleDetails.accEmailFlag : false,
        carDeliveryEmail: store.contactFlag ? store.contactRoleDetails.carDeliveryEmailFlag : false,
        documentsEmail: store.contactFlag ? store.contactRoleDetails.documentEmailFlag : false,
      });
    }
  }

  changeStoreSelected(storeId: string) {
    let userStores = this.usersStoresMap.get(this.userControl.value!)!;

    userStores[userStores.findIndex(s => s.id === this.selectedStoreControl.value!)] = {
      id: this.selectedStoreControl.value!,
      contactFlag: this.isContact,
      contactRoleDetails: {
        accEmailFlag: this.contactForm.controls.accountingEmail.value!,
        carDeliveryEmailFlag: this.contactForm.controls.carDeliveryEmail.value!,
        documentEmailFlag: this.contactForm.controls.documentsEmail.value!,
      }
    };

    this.patchContactForm(userStores.find(s => s.id === storeId)!);
  }
}
