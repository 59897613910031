import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, bufferCount, concatMap, from, mergeMap, of, scan, toArray } from 'rxjs';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import * as XLSX from 'xlsx';

interface BulkCarPhotoUploadI {
  name: string,
  content: string,
  vin: string
}

interface BulkCarPhotoUploadResponseI {
  successfullyUploaded: string[],
  incorrectPhotoName: string[],
  vinNotFound: string[],
  exceptionErrors: string[]
}

@Component({
  selector: 'app-multiple-photo-car-upload',
  templateUrl: './multiple-photo-car-upload.component.html',
  styleUrls: ['./multiple-photo-car-upload.component.scss']
})
export class MultiplePhotoCarUploadComponent implements OnInit {
  mapVINPhotos: Map<string, { name: string, content: string }[]> = new Map();

  photos: BulkCarPhotoUploadI[] = [];

  loading = new BehaviorSubject<boolean>(false);

  importResponse: BulkCarPhotoUploadResponseI[] = [];

  constructor(private utilsService: UtilsService,
    private carService: CarService,
    private snackbar: SnackbarService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit() {
  }

  async handleFileSelect(evt: Event) {
    this.mapVINPhotos = new Map();

    const target = evt.target as HTMLInputElement;

    const files = target.files as FileList;

    //converts all uploaded images in base64
    for (let i = 0; i < files.length; i++) {
      let originalPhoto = await this.utilsService.convertToBase64(files[i]);

      this.photos.push({
        name: files[i].name,
        content: originalPhoto,
        vin: files[i].name.split('_')[0]
      });
    }
  }

  uploadPhotos() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true)

    from(this.photos).pipe(
      bufferCount(100),
      mergeMap(batch => {
        let body: any = {
          nrOfPhotos: 0,
          cars: []
        }

        let vins = this.getDistinctByVin(batch);

        vins.forEach(vin => {
          let photos = batch.filter(p => p.vin === vin);

          body.cars.push({
            vin: vin,
            photos: photos.map(p => ({ name: p.name, content: p.content })),
            nrOfPhotos: photos.length
          });
        })

        body.nrOfPhotos = body.cars.reduce((sum: any, car: { nrOfPhotos: any; }) => car.nrOfPhotos + sum, 0);

        return this.carService.uploadBulkPhotos(body)
      }),
      toArray()
    ).subscribe({
      next: mergedResults => {
        this.importResponse = mergedResults;

        this.spinner.showProgressBar.next(false);
      },
      error: error => {
        this.spinner.showProgressBar.next(false);

        console.log(error);
      }
    })
  }

  getDistinctByVin(cars: BulkCarPhotoUploadI[]) {
    const seen = new Set<string>();

    cars.forEach(car => {
      if (!seen.has(car.vin)) {
        seen.add(car.vin);
      }
    });

    return seen;
  }

  exportFailedPhotos() {
    let exceptionPhotos = this.importResponse.reduce((arr: string[], resp: BulkCarPhotoUploadResponseI) => {
      return arr.concat(resp.exceptionErrors)
    }, []);

    let vinNotFoundPhotos = this.importResponse.reduce((arr: string[], resp: BulkCarPhotoUploadResponseI) => {
      return arr.concat(resp.vinNotFound)
    }, []);

    let incorrectPhotos = this.importResponse.reduce((arr: string[], resp: BulkCarPhotoUploadResponseI) => {
      return arr.concat(resp.incorrectPhotoName)
    }, []);

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    if (exceptionPhotos.length > 0) {
      this.addSheetToExcel(exceptionPhotos, 'ExceptionErrorPhotos', workbook);
    }

    if (vinNotFoundPhotos.length > 0) {
      this.addSheetToExcel(vinNotFoundPhotos, 'VINNotFoundPhotos', workbook);
    }

    if (incorrectPhotos.length > 0) {
      this.addSheetToExcel(incorrectPhotos, 'IncorectNamePhotos', workbook);
    }

    // Export the workbook to a file
    XLSX.writeFile(workbook, 'FailedPhotosExcel.xlsx');
  }

  addSheetToExcel(data: string[], sheetName: string, workbook: XLSX.WorkBook) {
    const exceptionPhotosColumnData = data.map(item => [item]); // Each element is in its own array, making it a single column

    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exceptionPhotosColumnData);

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  }

  getSuccessfulUploaded(): number {
    return this.importResponse.reduce((sum: number, resp: BulkCarPhotoUploadResponseI) => {
      return sum + resp.successfullyUploaded.length;
    }, 0)
  }

  getFailedUploaded(): number {
    return this.importResponse.reduce((sum: number, resp: BulkCarPhotoUploadResponseI) => {
      return sum + resp.exceptionErrors.length + resp.incorrectPhotoName.length + resp.vinNotFound.length;
    }, 0);
  }

  startNew() {
    this.photos = [];
    this.importResponse = [];
  }
}
