<div class="w-full flex flex-col gap-6" *ngIf="!(loading | async);else placeholder">
  <div id="reset-all-filters-btn" data-cy="reset-all-filters-btn"
    class="p-6 bg-white text-red flex justify-between rounded cursor-pointer active:scale-95"
    (click)="resetAllFilters()">
    <p class="text-sm">Reset all filters</p>
    <fa-icon [icon]="['far','xmark']"></fa-icon>
  </div>

  <div class="p-6 bg-white rounded" *ngIf="!isInternalShop">
    <p id="open-search-by-id-btn" data-cy="open-search-by-id-btn" class="text-sm cursor-pointer"
      (click)="openSearchByCarIdModal()">Search by car ID</p>
  </div>

  <app-expansion-panel panelId="Category" title="Category" [expandedPanel]="true" *ngIf="isInternalShop">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300">
      <mat-checkbox [id]="'category-' + (i + 1) + '-checkbox'" [attr.data-cy]="'category-' + (i + 1) + '-checkbox'"
        [value]="category.name" *ngFor="let category of filters.category; let i = index"
        (change)="changeFilter(category)" [(ngModel)]="category.isChecked">
        {{category.name}}</mat-checkbox>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="Focus on group" title="Focus on group" [expandedPanel]="true">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <mat-checkbox [id]="'group-' + (i + 1) + '-checkbox'" [attr.data-cy]="'group-' + (i + 1) + '-checkbox'"
        [value]="group.name" *ngFor="let group of filters.carGroup; let i = index" (change)="changeFilter(group)"
        [(ngModel)]="group.isChecked">
        {{group.name}}</mat-checkbox>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="carStatus" title="Car status" [expandedPanel]="true">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <mat-checkbox [id]="'car-status-' + (i + 1) + '-checkbox'" [attr.data-cy]="'car-status-' + (i + 1) + '-checkbox'"
        [value]="status.extraValue!" *ngFor="let status of filters.carStatus; let i = index"
        (change)="changeFilter(status)" [(ngModel)]="status.isChecked">
        {{status.name}}</mat-checkbox>
    </div>
  </app-expansion-panel>

  <app-make-model-filter #makeModelFilter (emitOnChangeSelect)="modifySelectedMakes()" [showMark]="false"
    (emitModelsSelect)="modifySelectedModels()" [id]="1" filterId="firstFilter">
  </app-make-model-filter>

  <app-make-model-filter #makeModelFilter (emitOnChangeSelect)="modifySelectedMakes()" [filterId]="makeModelFilter"
    *ngFor="let makeModelFilter of makeModelFilters; let i = index" [id]="i + 2"
    (emitRemoveFilter)="removeMakeModelFilter($event)" (emitModelsSelect)="modifySelectedModels()">
  </app-make-model-filter>

  <div class="bg-100 border border-white-500 rounded flex justify-center py-2 cursor-pointer active:scale-95"
    (click)="addMakeModelFilter()" id="add-make-model-filter-btn" data-cy="add-make-model-filter-btn">
    <fa-icon [icon]="['fal','plus']"></fa-icon>
  </div>

  <app-expansion-panel panelId="Fuel Type" [title]="'Fuel Type'" [expandedPanel]="true">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <mat-checkbox [id]="'fueltype-' + (i + 1) + '-checkbox'" [attr.data-cy]="'fueltype-' + (i + 1) + '-checkbox'"
        [value]="fueltype.name" *ngFor="let fueltype of filters.fuelType; let i = index"
        (change)="changeFilter(fueltype)" [(ngModel)]="fueltype.isChecked">
        {{fueltype.name}}</mat-checkbox>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="Gearbox" title="Gearbox">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300">
      <mat-checkbox [id]="'gearbox-' + (i + 1) + '-checkbox'" [attr.data-cy]="'gearbox-' + (i + 1) + '-checkbox'"
        [value]="gearbox.name" *ngFor="let gearbox of filters.gearbox; let i = index" (change)="changeFilter(gearbox)"
        [(ngModel)]="gearbox.isChecked">
        {{gearbox.name}}</mat-checkbox>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="Mileage" title="Mileage">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <app-input id="mileage-from" [isNumberInput]="true" placeholder="Insert KM from" label="KM from"
        [control]="mileageFromControl"></app-input>
      <app-input id="mileage-to" [isNumberInput]="true" placeholder="Insert KM up to" label="KM up to"
        [control]="mileageToControl"></app-input>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="ManufactureYear" title="Manufacture Year">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300">
      <app-dropdown id="year-from" [control]="manufactureYears.controls.from" label="Year from"
        [placeholder]="'Select year from'" [options]="yearsFrom" (emitOnChangeSelect)="selectManYear('from',$event )"
        [resetValue]="true"></app-dropdown>

      <app-dropdown id="year-to" [control]="manufactureYears.controls.to" label="Year up to"
        [placeholder]="'Select year up to'" [options]="yearsTo" (emitOnChangeSelect)="selectManYear('to',$event)"
        [resetValue]="true"></app-dropdown>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="Engine power" title="Engine power">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <app-input id="engine-power-from" [isNumberInput]="true" [placeholder]="'Insert HP from'" label="HP from"
        [control]="enginePowFromControl">
      </app-input>
      <app-input id="engine-power-to" [isNumberInput]="true" [placeholder]="'Insert HP up to'" label="HP up to"
        [control]="enginePowToControl">
      </app-input>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="Body type" title="Body type">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <mat-checkbox [id]="'bodytype-' + (i + 1) + '-checkbox'" [attr.data-cy]="'bodytype-' + (i + 1) + '-checkbox'"
        [value]="bodyType.name" *ngFor="let bodyType of filters.bodyType; let i = index"
        (change)="changeFilter(bodyType)" [(ngModel)]="bodyType.isChecked">{{bodyType.name}}
      </mat-checkbox>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="Sellers" title="Sellers" #sellerPanel [checkResize]="true">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <input id="search-seller-input" data-cy="search-seller-input" class="search-seller" type="text" matInput
        [matAutocomplete]="auto" [formControl]="sellerControl" placeholder="Search seller">

      <mat-autocomplete #auto="matAutocomplete">
        <div *ngIf="sellerResults.length > 0">
          <mat-option class="autocomplete-option" *ngFor="let seller of sellerResults; let i = index"
            (click)="selectSeller(seller)">
            {{seller.sellerCompanyDetails.name}}
          </mat-option>
        </div>
      </mat-autocomplete>

      <div *ngIf="selectedSeller">
        <p>Selected seller: {{selectedSeller.sellerCompanyDetails.name}}</p>
      </div>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="journey" title="Journey">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <app-datepicker id="journey-from" label="Journey from" [control]="journeyFromControl">
      </app-datepicker>

      <app-datepicker id="journey-to" label="Journey to" [control]="journeyToControl">
      </app-datepicker>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="VAT" title="VAT">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300">
      <mat-radio-group (change)="selectVATFilter($event)" [formControl]="vatControl"
        class="flex flex-col gap-4 text-sm sm:justify-evenly" aria-label="Select an option">
        <mat-radio-button [value]="true">{{vatTypes.ExVAT}}</mat-radio-button>
        <mat-radio-button [value]="false">{{vatTypes.InclVAT}}</mat-radio-button>
        <mat-radio-button [value]="'None'">None</mat-radio-button>
      </mat-radio-group>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="vin" title="VIN">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <app-input id="vin" placeholder="Insert VIN" label="VIN" [control]="vinControl"></app-input>
    </div>
  </app-expansion-panel>

  <app-expansion-panel panelId="Location" title="Location">
    <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
      <mat-checkbox [id]="'location-' + (i + 1) + '-checkbox'" [attr.data-cy]="'location-' + (i + 1) + '-checkbox'"
        [value]="country.name" *ngFor="let country of filters.location; let i = index" (change)="changeFilter(country)"
        [(ngModel)]="country.isChecked">{{country.name}}
      </mat-checkbox>
    </div>
  </app-expansion-panel>
</div>

<ng-template #placeholder>
  <div class="flex flex-col gap-6">
    <div class="bg-white rounded flex flex-col gap-4 p-6" *ngFor="let i of [].constructor(5)">
      <div class="placeholder-animation w-full h-6"> </div>
      <div class="h-[1px] bg-white-500 w-full"></div>
      <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(3)"></div>
    </div>
  </div>
</ng-template>
