import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Order, OrderCar, OrderTask, UpdateOrderOptionsBody } from 'src/app/core/models/order.model';
import { OrdersFlowSyncService } from 'src/app/core/services/order-flow.service';
import { OrdersService } from 'src/app/core/services/orders.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { OrderStatus } from '../orders-view/orders-view.component';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-order-process',
  templateUrl: './order-process.component.html',
  styleUrls: ['./order-process.component.scss'],
  providers: []
})

export class OrderProcessComponent implements OnInit {
  loading = this.orderFlowService.loading;

  routes = [
    {
      viewValue: "Order setup",
      value: "setup",
      disabled: new BehaviorSubject<boolean>(false)
    },
    {
      viewValue: "Buyer",
      value: "buyer",
      disabled: new BehaviorSubject<boolean>(true)
    },
    {
      viewValue: "Seller",
      value: "seller",
      disabled: new BehaviorSubject<boolean>(true)
    },
    {
      viewValue: "Cars",
      value: "cars",
      disabled: new BehaviorSubject<boolean>(true)
    },
    {
      viewValue: "Payment",
      value: "payment",
      disabled: new BehaviorSubject<boolean>(true)
    },
    {
      viewValue: "Transportation",
      value: "transportation",
      disabled: new BehaviorSubject<boolean>(true)
    },
    {
      viewValue: "Documents",
      value: "documents",
      disabled: new BehaviorSubject<boolean>(true)
    },
    {
      viewValue: "Profit analysis",
      value: "profit",
      disabled: new BehaviorSubject<boolean>(true)
    },
    // {
    //   viewValue: "Claims",
    //   value: "claims",
    // },
    // {
    //   viewValue: "Logs",
    //   value: "logs",
    // },
    // {
    //   viewValue: "Chat",
    //   value: "chat",
    // }
  ];

  page = this.routes[0].value;

  cars: OrderCar[] = [];
  notes = '';
  dhlValue = 0;

  orderTasks: OrderTask[] = [];

  orderOptions = this.fb.group({
    dhlActualValue: [0, Validators.required],
    requestDate: new FormControl<any>('', Validators.required),
    paymentDate: new FormControl<any>('', Validators.required),
    transportC2C: [true],
    separateTransportInvoice: [false],
    invoicePerCar: [false],
  });

  order: Order | undefined;

  finishedOrder = this.orderFlowService.orderStatus === OrderStatus.FINISHED;

  subStatuses: DropdownOption[] = [];
  subStatusControl = new FormControl();

  private subscriptions: Subscription = new Subscription();

  constructor(private activatedRoutes: ActivatedRoute,
    private orderFlowService: OrdersFlowSyncService,
    private orderService: OrdersService,
    private fb: FormBuilder,
    private spinner: SpinnerHandlerService,
    private snackbar: SnackbarService,
    private utilsService: UtilsService,
    private router: Router) { }

  ngOnInit(): void {
    this.subscriptions.add(this.activatedRoutes.params.subscribe(async params => {
      if (params['orderId']) {
        let order = await this.orderFlowService.loadSentOrder(params['orderId'], this.router.url.includes('/order'));

        this.order = order;

        this.subStatuses = this.orderFlowService.orderSubStatuses;
        this.subStatusControl.setValue(order.subStatus);

        this.updateInfo(order);

        this.finishedOrder = this.orderFlowService.orderStatus === OrderStatus.FINISHED;

        if (this.cars.every(c => c.invoicingCompany.id)) {
          this.routes.forEach(r => r.disabled!.next(false));

          this.routes[7].disabled?.next(true);
        }

        if (this.finishedOrder) {
          this.orderOptions.disable();
        }
      }
    }));

    this.subscriptions.add(this.orderFlowService.orderInfoUpdateSubjectEvent$.subscribe(resp => {
      this.updateInfo(this.orderFlowService.order!);
    }));

    this.subscriptions.add(this.orderFlowService.orderTasksEvent$.subscribe(resp => {
      this.orderTasks = this.orderFlowService.alterOrderTasks(resp, this.orderFlowService.order!);
    }));

    this.subscriptions.add(this.orderFlowService.continueEvent$.subscribe(resp => {
      this.routes.forEach(r => r.disabled!.next(false));

      this.routes[7].disabled?.next(true);

      this.changePage(this.routes[1].value);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

    this.orderFlowService.resetInfo();
  }

  changePage(event: string) {
    this.page = event;
  }

  updateInfo(order: Order) {
    this.cars = this.orderFlowService.carList;
    this.orderTasks = this.orderFlowService.orderTasks;

    this.orderOptions.patchValue({
      requestDate: order.requestedDeliveryDate,
      paymentDate: order.estimatedPaymentDate,
      transportC2C: order.transportDetails.transportByC2C,
      separateTransportInvoice: order.separateInvoiceForTransport,
      invoicePerCar: order.oneInvoicePerCar,
      dhlActualValue: order.dhlValue
    });

    this.notes = order.notes;
    this.dhlValue = order.dhlValue;
  }

  checkTransports(): boolean {
    return this.orderFlowService.order!.transportDetails.transports.length > 0;
  }

  saveOrderOptions() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    let requestDate = this.orderOptions.controls.requestDate.value;
    let paymentDate = this.orderOptions.controls.paymentDate.value;

    let body: UpdateOrderOptionsBody = {
      orderId: this.orderFlowService.orderId!,
      dhlValue: this.orderOptions.controls.dhlActualValue.value ? this.orderOptions.controls.dhlActualValue.value : 0,
      requestedDeliveryDate: requestDate && requestDate != "None" ? this.utilsService.formatDateValue(requestDate) : '',
      estimatedPaymentDate: paymentDate && paymentDate != "None" ? this.utilsService.formatDateValue(paymentDate) : '',
      transportByC2C: this.orderOptions.controls.transportC2C.value ? this.orderOptions.controls.transportC2C.value : false,
      separateInvoiceForTransport: this.orderOptions.controls.separateTransportInvoice.value ? this.orderOptions.controls.separateTransportInvoice.value : false,
      oneInvoicePerCar: this.orderOptions.controls.invoicePerCar.value ? this.orderOptions.controls.invoicePerCar.value : false,
      notes: this.notes,
    };

    this.orderService.updateOrderOptions(body).subscribe({
      next: resp => {
        this.orderFlowService.order = resp;

        this.order = resp;

        this.orderFlowService.loadProfits(resp);

        this.orderFlowService.emitOrderInfoUpdate();

        this.snackbar.positiveSentiment('Order options saved');
        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }

  finalizeOrder() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    this.orderService.finalizeOrder(this.order!.orderId).subscribe({
      next: resp => {
        this.snackbar.positiveSentiment('Order finished');

        this.spinner.showProgressBar.next(false);

        this.router.navigate(['orders']);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }

  updateSubStatus() {
    if (this.spinner.showProgressBar.value) return;

    if (!this.subStatusControl.value) {
      this.snackbar.negativeSentiment('Select substatus');

      return;
    }

    this.spinner.showProgressBar.next(true);

    this.orderService.updateOrderSubstatus(this.order!.orderId, this.subStatusControl.value!).subscribe({
      next: resp => {
        this.snackbar.positiveSentiment('Order substatus updated');

        this.orderFlowService.order!.subStatus = this.subStatusControl.value;

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }
}
