<div class="twoCols">
  <div class="col-span-2 flex justify-between items-center">
    <app-dropdown id="stores" [options]="storesDropdown" label="Store" [control]="storeToggleControl"
      (emitOnChangeSelect)="changeStore($event)"></app-dropdown>

    <div class="flex gap-6 items-center">
      <primary-btn id="save-store" btnText="Save Store" (clickEmitter)="saveStore()"></primary-btn>

      <fa-icon class="text-2xl border rounded px-4 cursor-pointer active:scale-95 py-1" [matMenuTriggerFor]="moreBtns"
        [icon]="['fal','ellipsis-vertical']"></fa-icon>

      <mat-menu #moreBtns="matMenu">
        <button mat-menu-item (click)="addNewStore()">Add new store</button>
        <button mat-menu-item (click)="copyData()">Copy data from main company</button>
        <button mat-menu-item (click)="copyMakes()">Copy makes from main company</button>
      </mat-menu>
    </div>
  </div>

  <div class="col-span-1 flex flex-col gap-6">
    <!-- store profile -->
    <app-main-content-layout>
      <div class="title">
        <p>Store profile</p>
      </div>

      <app-main-content-row-input title="Store name" componentId="store-name" placeholder="Insert store name"
        [control]="storeForm.controls.storeName"></app-main-content-row-input>

      <app-main-content-row-layout>
        <p [ngClass]="{'text-red font-bold' : storeForm.controls.city.invalid && storeForm.controls.city.touched}">
          Region*</p>

        <div class="secondContent twoCols">
          <app-read-only-input>{{companyCountry.name}}</app-read-only-input>

          <app-input id="city" class="col-span-1" [control]="storeForm.controls.city" placeholder="Insert city">
          </app-input>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-input title="ZIP/Postal code" componentId="postalcode" placeholder="Insert postal code"
        [control]="storeForm.controls.postalCode"></app-main-content-row-input>

      <app-main-content-row-input title="Address" componentId="address" placeholder="Insert address"
        [control]="storeForm.controls.address"></app-main-content-row-input>

      <app-main-content-row-layout>
        <p>Cars2view ID</p>
        <app-input id="c2v-id" class="secondContent" placeholder="Insert cars2view id"></app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Use country’s bank holidays</p>

        <app-toggle id="country-holidays" class="secondContent"
          [toggleFormControl]="storeForm.controls.countryBank"></app-toggle>
      </app-main-content-row-layout>

      <!-- <div class="grid grid-cols-3 xl:grid-cols-2 gap-4 xl:gap-6">
        <div
          class="col-span-2 xl:col-span-1 col-start-2 xl:col-start-2 flex flex-col 2xl:items-center 2xl:flex-row gap-6">
          <tertiary-btn id="generate-profile" [btnText]="'Generate profile'" class=""></tertiary-btn>
          <p class="text-blue-500 underline text-center">see on map</p>
        </div>
      </div> -->
    </app-main-content-layout>

    <!-- add basic profile -->
    <app-main-content-layout>
      <div class="title">
        <p>Add basic profile</p>
      </div>
      <!--
      <app-main-content-row-layout>
        <p>Status</p>
        <app-app-radio-btns class="secondContent" [options]="statusRadio"></app-app-radio-btns>
      </app-main-content-row-layout> -->

      <app-main-content-row-dropdown title="Dealer group makes" componentId="group-makes"
        placeholder="Select dealer group makes" [control]="storeForm.controls.make" [options]="makes"
        [multipleSelect]="true" [selectAll]="true"></app-main-content-row-dropdown>

      <app-main-content-row-input-number title="Estimated monthly car sales" componentId="estimated-sales"
        placeholder="Insert estimated monthly car sales"
        [control]="storeForm.controls.carSalesEst"></app-main-content-row-input-number>
    </app-main-content-layout>
  </div>

  <!-- right side panel -->
  <div class="col-span-1 flex flex-col gap-6">
    <app-main-content-layout>
      <div class="title">
        <p>Store info</p>
      </div>
      <app-main-content-row-layout>
        <p>Status</p>
        <div class="secondContent text-end">
          <app-toggle id="status" class="secondContent" [toggleFormControl]="statusControl"
            [toggleLabel]="statusControl.value? 'Active' :'Inactive'"></app-toggle>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Date created</p>

        <app-read-only-input class="secondContent">
          {{dateCreated | date:'dd/MM/yyyy'}}
        </app-read-only-input>
      </app-main-content-row-layout>
    </app-main-content-layout>

    <app-main-content-layout>
      <div class="title">
        Notes
      </div>

      <textarea id="notes-textarea" data-cy="notes-textarea" [(ngModel)]="notes" rows="15"
        class="w-full rounded border border-white-500 p-6 text-blue-100 text-sm focus:outline-none"
        placeholder="Insert notes here..."></textarea>
    </app-main-content-layout>
  </div>
</div>
