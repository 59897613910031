import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { OnlineListCarPhotosDocsModalComponent } from '../online-list-car-photos-docs-modal/online-list-car-photos-docs-modal.component';
import { Subscription } from 'rxjs';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-online-list-upload-photos',
  templateUrl: './online-list-upload-photos.component.html',
  styleUrls: ['./online-list-upload-photos.component.scss']
})
export class OnlineListUploadPhotosComponent implements OnInit {
  routeSubscription = new Subscription();

  tableMap: TableHeaderMap[] = [
    {
      value: 'regNo',
      tableView: 'reg number'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'variant',
      tableView: 'variant'
    },
    {
      value: 'trim',
      tableView: 'trim'
    },
    {
      value: 'manufactureYear',
      tableView: 'model Year'
    },
    {
      value: 'firstReg',
      tableView: 'first Reg'
    },
    {
      value: 'fuelType',
      tableView: 'fuel Type'
    },
    {
      value: 'gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'bodyType',
      tableView: 'chassis'
    },
    {
      value: 'enginePower',
      tableView: 'engine Power'
    },
    {
      value: 'engineVolume',
      tableView: 'engine volume'
    },
    {
      value: 'color',
      tableView: 'color'
    },
    {
      value: 'doors',
      tableView: 'doors'
    },
    {
      value: 'seats',
      tableView: 'seats'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'damages',
      tableView: 'damages'
    },
    {
      value: 'minSellingPrice',
      tableView: 'min Selling Price'
    },
    {
      value: 'buyingPrice',
      tableView: 'buying Price'
    },
  ];

  displayedColumns = [...this.tableMap.map(t => t.value), 'edit'];

  cars = this.carFlowSyncService.carsAfterIdentifyDTO;

  @ViewChild('carsTable') carsTable: AppTableComponent | undefined;

  constructor(private carFlowSyncService: CarFlowSyncService,
    private carService: CarService,
    private spinner: SpinnerHandlerService,
    private snackbar: SnackbarService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.routeSubscription = this.carFlowSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.carFlowSyncService.setCurrentTab(value);
      }
    );
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  openModal(car: any) {
    const dialogRef = this.dialog.open(
      OnlineListCarPhotosDocsModalComponent, {
      width: '1200px',
      maxWidth: '95vw',
      autoFocus: false,
      disableClose: true,
      data: {
        car: car,
      }
    });

    dialogRef.afterClosed().subscribe(car => {
      let index = this.carFlowSyncService.carsAfterIdentifyDTO.findIndex(c => c.carMainInfoId === car.carMainInfoId)!;

      this.carFlowSyncService.carsAfterIdentifyDTO[index] = car;

      this.carsTable?.updateTable(this.carFlowSyncService.carsAfterIdentifyDTO);
    })
  }

  setDefaultPhotoAllCars() {
    if (this.spinner.showProgressBar.value) return;

    this.spinner.showProgressBar.next(true);

    let body = this.cars.map(c => ({ idAdac: c.idAdac, carMainInfoId: c.carMainInfoId }));

    this.carService.setDefaultPhotoOnlineListCars(body).subscribe({
      next: resp => {
        resp.forEach((car: any) => {
          let index = this.cars.findIndex(c => c.carMainInfoId === car.carMainInfoId);

          let photo = {
            originalPhoto: car.profilePhotoUrl,
            _id: car.profilePhotoId
          }

          if (this.cars[index].photos) {
            this.cars[index].photos.push(photo);
          } else {
            this.cars[index].photos = [photo];
          }
        });

        this.carsTable?.updateTable(this.cars);

        this.snackbar.positiveSentiment('Imported generic photo for all cars');

        this.spinner.showProgressBar.next(false);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.spinner.showProgressBar.next(false);
      }
    })
  }
}
