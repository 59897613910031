import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Buyer, BuyerSearchBody } from 'src/app/core/models/buyer.model';
import { Status } from 'src/app/core/models/info.model';
import { BuyerService } from 'src/app/core/services/buyer.service';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { InfoService } from 'src/app/core/services/infos.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppTableComponent, TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-view-buyers',
  templateUrl: './view-buyers.component.html',
  styleUrls: ['./view-buyers.component.scss']
})

export class ViewBuyersComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  loadingTable = new BehaviorSubject<boolean>(false);

  tableColumns: TableHeaderMap[] = [
    {
      tableView: 'COUNTRY',
      value: 'country'
    },
    {
      tableView: 'KAM',
      value: 'kamName'
    },
    {
      tableView: 'NAME',
      value: 'name'
    },
    {
      tableView: 'Priority',
      value: 'priority'
    },
    {
      tableView: 'status',
      value: 'statusTable'
    },
    {
      tableView: 'profile',
      value: 'makes'
    }
  ];

  displayedColumns = this.tableColumns.map(t => t.value).concat(['details']);

  buyers: Buyer[] = [];

  filterControl = this.fb.group({
    vat: [],
    name: [],
    kam: [],
    username: [],
    country: [],
    makes: [],
    priority: [],
    status: [],
  })

  countries: DropdownOption[] = this.coreService.countries.map(c => ({ value: c.name, viewValue: c.name }));
  priorities: DropdownOption[] = [];
  kams: DropdownOption[] = [];
  makes: DropdownOption[] = [];
  statuses: DropdownOption[] = []

  isAdmin = this.coreService.isAdmin;

  results = 0;

  @ViewChild('buyersTable') buyersTable: AppTableComponent | undefined;
  @ViewChild('paginator') paginator: MatPaginator | undefined;

  constructor(private router: Router,
    private buyerService: BuyerService,
    private coreService: CoreService,
    private infoService: InfoService,
    private carService: CarService,
    private fb: FormBuilder) { }

  ngOnInit() {
    let body: BuyerSearchBody = {
      page: 0,
      itemsPerPage: 5
    };

    forkJoin({
      buyers: this.buyerService.searchBuyers(body),
      priorities: this.infoService.getPriorities(),
      makes: this.carService.getCarNomenclatorData('makes')
    }).subscribe(resp => {
      this.priorities = resp.priorities.map(p => ({ viewValue: p.priority, value: p.priority }));
      this.makes = resp.makes.map(m => ({ viewValue: m, value: m }));

      this.buyers = resp.buyers.companies.map(b => ({
        ...b,
        statusTable: b.status ? Status.Active : Status.Inactive,
        profile: b.makes.join(', ')
      }));

      this.results = resp.buyers.nrOfCompanies;

      this.statuses = Object.keys(Status).map(s => ({ value: s, viewValue: s }));

      this.loading.next(false);
    });
  }

  goToCompanyView() {
    this.router.navigate(['buyer/company']);
  }

  applyFilters(resetPage?: boolean) {
    this.loadingTable.next(true);

    let body: BuyerSearchBody = {
      page: resetPage ? 0 : this.paginator!.pageIndex,
      itemsPerPage: this.paginator!.pageSize,
      vat: this.filterControl.controls.vat.value ? this.filterControl.controls.vat.value : undefined,
      name: this.filterControl.controls.name.value ? this.filterControl.controls.name.value : undefined,
      username: this.filterControl.controls.username.value ? this.filterControl.controls.username.value : undefined,
      country: this.filterControl.controls.country.value ? this.filterControl.controls.country.value : undefined,
      kam: this.filterControl.controls.kam.value ? this.filterControl.controls.kam.value : undefined,
      makes: this.filterControl.controls.makes.value ? this.filterControl.controls.makes.value : undefined,
      priority: this.filterControl.controls.priority.value ? this.filterControl.controls.priority.value : undefined,
      status: this.filterControl.controls.status.value ? (this.filterControl.controls.status.value === Status.Active ? true : false) : undefined,
    }

    this.buyerService.searchBuyers(body).subscribe(resp => {
      this.buyers = resp.companies.map(b => ({
        ...b,
        statusTable: b.status ? Status.Active : Status.Inactive,
        profile: b.makes.join(', ')
      }));

      this.results = resp.nrOfCompanies;

      if (resetPage) this.paginator!.pageIndex = 0;

      this.loadingTable.next(false);
    });
  }


  resetFilters() {
    this.filterControl.reset();

    this.applyFilters(true);
  }

  goToBuyer(buyer: Buyer) {
    this.router.navigate([`buyer/company/${buyer.id}`]);
  }
}
