<div class="flex flex-col gap-9">
  <app-main-content-layout>
    <div class="title">
      <p>KAM users create bulk</p>
    </div>

    <div class="flex justify-between">
      <div>
        <input [multiple]="false" #selectExcel type="file" accept=".xlsx" (change)="handleKAMUsersFileSelect($event)">
      </div>

      <div>
        <primary-btn (clickEmitter)="uploadKAMS()">Upload KAMS</primary-btn>
      </div>
    </div>

  </app-main-content-layout>


  <app-main-content-layout>
    <div class="title">
      <p>Buyer companies create bulk</p>
    </div>

    <div class="flex justify-between">
      <div>
        <input [multiple]="false" #selectExcel type="file" accept=".xlsx"
          (change)="handleBuyerCompanyFileSelect($event)">
      </div>

      <div>
        <primary-btn (clickEmitter)="uploadBuyers()">Upload buyer companies</primary-btn>
      </div>
    </div>

  </app-main-content-layout>

  <app-main-content-layout>
    <div class="title">
      <p>Seller companies create bulk</p>
    </div>

    <div class="flex justify-between">
      <div>
        <input [multiple]="false" #selectExcel type="file" accept=".xlsx"
          (change)="handleSellerCompanyFileSelect($event)">
      </div>

      <div>
        <primary-btn (clickEmitter)="uploadSellers()">Upload seller companies</primary-btn>
      </div>
    </div>

  </app-main-content-layout>

  <app-main-content-layout>
    <div class="title">
      <p>Buyer & seller users create bulk</p>
    </div>

    <div class="flex justify-between">
      <div>
        <input [multiple]="false" #selectExcel type="file" accept=".xlsx" (change)="handleFile($event)">
      </div>

      <div>
        <primary-btn (clickEmitter)="uploadBuyersSellersUsers()">Upload users</primary-btn>
      </div>
    </div>

  </app-main-content-layout>

  <app-main-content-layout>
    <div class="title">
      <p>KAM users match bulk</p>
    </div>

    <div class="flex justify-between">
      <div>
        <input [multiple]="false" #selectExcel type="file" accept=".xlsx" (change)="handleKAMMatchFileSelect($event)">
      </div>

      <div>
        <primary-btn (clickEmitter)="matchKAMS()">Upload KAMS</primary-btn>
      </div>
    </div>

  </app-main-content-layout>
</div>
