import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CountryHoliday } from "src/app/features/settings/country-holiday/country-holiday.component";
import { CreateTransportMatrixBody, EditTransportMatrixBody, TransportMatrix } from "src/app/features/settings/transport-matrix/transport-matrix.component";
import { environment } from "src/environments/environment";
import { Dealer, DealerPortal } from "../models/seller.model";
import { CompanyInvoice } from "src/app/features/settings/company-invoices/company-invoices.component";
import { Constants } from "../models/info.model";

@Injectable({
  providedIn: 'root'
})

export class SettingsService {
  constructor(private http: HttpClient) { }

  public createTransportMatrix(body: CreateTransportMatrixBody): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}transport-matrix-create`, body);
  }

  public editTransportMatrix(matrix: EditTransportMatrixBody, matrixId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}transport-matrix-edit/${matrixId}`, matrix);
  }

  public getTransportMatrix(): Observable<TransportMatrix[]> {
    return this.http.get<TransportMatrix[]>(`${environment.backendApiUrl}transport-matrixes`);
  }

  public getPortalId(iso: string): Observable<DealerPortal[]> {
    return this.http.post<DealerPortal[]>(`${environment.backendApiUrl}identify-portal-id`, { iso: iso });
  }

  public getDealers(dealer: string, portalId: number): Observable<Dealer[]> {
    return this.http.post<Dealer[]>(`${environment.backendApiUrl}identify-dealer`, {
      dealer: dealer,
      portalId: portalId
    });
  }

  public getCountryHolidays(countryId: string): Observable<CountryHoliday[]> {
    return this.http.get<CountryHoliday[]>(`${environment.backendApiUrl}country-holidays/${countryId}`);
  }

  public createCountryHoliday(countryId: string, date: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}country-holiday-create`, { country: countryId, date: date });
  }

  public deleteCountryHoliday(holidayId: string): Observable<string> {
    return this.http.delete<string>(`${environment.backendApiUrl}country-holiday-delete/${holidayId}`);
  }

  public createCarGroup(name: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}car-group-create`, { name: name });
  }

  public deleteCarGroup(groupId: string): Observable<string> {
    return this.http.delete<string>(`${environment.backendApiUrl}car-group-delete/${groupId}`);
  }

  public getConstants(): Observable<Constants[]> {
    return this.http.get<Constants[]>(`${environment.backendApiUrl}get-constants`);
  }

  public createCompanyInvoice(company: CompanyInvoice): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}invoicing-company-create`, company);
  }

  public editCompanyInvoice(company: CompanyInvoice, companyId: string): Observable<string> {
    return this.http.post<string>(`${environment.backendApiUrl}invoicing-company-edit/${companyId}`, company);
  }

  public getCompanyInvoice(): Observable<CompanyInvoice[]> {
    return this.http.get<CompanyInvoice[]>(`${environment.backendApiUrl}invoicing-companies`);
  }

  public deleteCompanyInvoice(companyId: string): Observable<string> {
    return this.http.delete<string>(`${environment.backendApiUrl}invoicing-company-delete/${companyId}`);
  }



  // temporary endpoints
  public uploadKAMSBulk(body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}upload-bulk-kam-users`, body);
  }

  public matchKAMSBulk(body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}match-kams-to-companies`, body);
  }

  public uploadSellersBulk(body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}upload-bulk-seller-companies`, body);
  }

  public uploadBuyersBulk(body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}upload-bulk-buyer-companies`, body);
  }

  public uploadBuyersSellersUsersBulk(body: any): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}upload-buyer-seller-users`, body);
  }
}
