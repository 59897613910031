import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CreateSellerUserBody, EditSellerUserBody } from 'src/app/core/models/seller-models/seller-edit.model';
import { ISellerUser } from 'src/app/core/models/seller.model';
import { UserRoles } from 'src/app/core/models/user.model';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';

interface UserStore {
  id: string,
  contactFlag: boolean,
  contactRoleDetails: {
    accEmailFlag: boolean,
    carPickupEmailFlag: boolean,
    documentEmailFlag: boolean,
  }
}

@Component({
  selector: 'app-view-b2b-seller-users',
  templateUrl: './view-b2b-seller-users.component.html',
  styleUrls: ['./view-b2b-seller-users.component.scss']
})
export class ViewB2bSellerUsersComponent implements OnInit {
  isAdmin = this.coreService.isAdmin;
  editMode = false;

  createMode: boolean = false;
  sellerId = this.b2bSyncService.sellerId;

  routeSubscription: Subscription = new Subscription();

  dateCreated: Date | undefined;
  dateUpdated: Date | undefined;

  statusControl = new FormControl({ value: false, disabled: true });

  userDetailsForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    countryId: ['', Validators.required],
    phoneNumber: [''],
  });

  userEmail = '';
  userEmailControl = new FormControl('', [Validators.required, Validators.email]);

  contactForm = this.fb.group({
    accountingEmail: new FormControl<boolean>(false),
    carPickupEmail: new FormControl<boolean>(false),
    documentsEmail: new FormControl<boolean>(false),
  })

  selectedStoreControl = new FormControl();
  storesSelected: DropdownOption[] = [];

  stores: DropdownOption[] = this.b2bSyncService.sellerResponse!.stores.map(s => ({ viewValue: s.name!, value: s.id! }));
  storeControl = new FormControl<string[]>([''], Validators.required);

  userDropdown: DropdownOption[] = this.b2bSyncService.sellerResponse!.users.map(u => ({ viewValue: `${u.firstName} ${u.lastName}`, value: u.id }));
  userControl = new FormControl();

  countries = this.coreService.countriesDropdown;

  isContact = false;

  usersStoresMap: Map<string, UserStore[]> = new Map();

  constructor(private b2bSyncService: B2BSellerSyncService,
    private fb: FormBuilder,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private sellerService: SellerService,
    private spinner: SpinnerHandlerService) { }

  ngOnInit() {
    this.routeSubscription = this.b2bSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.b2bSyncService.setCurrentTab(value);
      }
    );

    this.b2bSyncService.sellerResponse!.users.forEach(u => {
      this.usersStoresMap.set(u.id, u.details.sellerStores.map(s => ({
        id: s.store,
        contactFlag: s.roles.includes('contact'),
        contactRoleDetails: s.contactRoleDetails
      })));
    });

    if (this.userDropdown.length > 0) {
      this.userControl.setValue(this.userDropdown[0].value);
      this.patchUserForm(this.b2bSyncService.sellerResponse!.users.find(u => u.id === this.userDropdown[0].value)!);
    }

    this.userDetailsForm.disable();
    this.contactForm.disable();
    this.storeControl.disable();
    this.selectedStoreControl.disable();
  }

  saveUser() {
    if (this.spinner.showProgressBar.value) return;

    if (this.userDetailsForm.invalid) {
      this.snackbar.negativeSentiment('Please fill all required fields');

      this.userDetailsForm.markAllAsTouched();
      this.storeControl.markAsTouched();

      return;
    }

    this.spinner.showProgressBar.next(true);

    let stores = this.usersStoresMap.get(this.userControl.value!)!;

    if (this.selectedStoreControl.value) {
      stores[stores.findIndex(s => s.id === this.selectedStoreControl.value!)] = {
        id: this.selectedStoreControl.value!,
        contactFlag: this.isContact,
        contactRoleDetails: {
          accEmailFlag: this.contactForm.controls.accountingEmail.value!,
          carPickupEmailFlag: this.contactForm.controls.carPickupEmail.value!,
          documentEmailFlag: this.contactForm.controls.documentsEmail.value!,
        }
      }
    }

    if (this.createMode) {
      if (this.userDetailsForm.invalid && this.userEmailControl.invalid) {
        this.userDetailsForm.markAllAsTouched();
        this.userEmailControl.markAsTouched();

        this.snackbar.negativeSentiment('Complete all required fields');

        return;
      }

      let body = new CreateSellerUserBody(this.sellerId!, this.userDetailsForm.value, stores, this.userEmailControl.value!);

      this.sellerService.createSellerUser(body).subscribe({
        next: resp => {
          this.b2bSyncService.sellerResponse!.users.push({
            email: body.email,
            firstName: body.firstName,
            lastName: body.lastName,
            username: body.email!,
            phoneNumber: body.phoneNumber,
            isActive: true,
            details: {
              sellerCompany: this.sellerId!,
              sellerStores: stores.map(s => ({
                store: s.id,
                roles: s.contactFlag ? ['user', 'contact'] : ['user'],
                contactRoleDetails: s.contactRoleDetails
              })),
            },
            id: resp,
            createdAt: new Date().getTime(),
            modifiedAt: new Date().getTime(),
            countryId: body.countryId,
          });

          this.userDropdown = this.b2bSyncService.sellerResponse!.users.map(u => ({ viewValue: `${u.firstName} ${u.lastName}`, value: u.id }));

          this.userEmail = body.email;

          this.userControl.setValue(resp);

          this.usersStoresMap.delete('newUser');

          this.usersStoresMap.set(resp, stores);

          this.createMode = false;

          this.spinner.showProgressBar.next(false);

          this.snackbar.positiveSentiment('User created');
        },
        error: err => {
          this.spinner.showProgressBar.next(false);

          this.snackbar.negativeSentiment(err.error);
        }
      });
    } else {
      let body = new EditSellerUserBody(this.sellerId!, this.userDetailsForm.value, stores, this.userControl.value!, this.statusControl.value!);

      this.editUser(body);
    }
  }


  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  async changeUser(userId: string) {
    if (this.createMode) {
      const currentUserId = this.userControl.value;

      let resp = await this.coreService.openUnsavedChangesModal();

      if (resp) {
        if (currentUserId) this.userDropdown = this.b2bSyncService.sellerResponse!.users.map(c => ({ viewValue: `${c.firstName} ${c.lastName}`, value: c.id! }));

        this.continueChangeUserFlow(userId);
      } else {
        this.userControl.setValue(currentUserId);
      }
    } else {
      this.continueChangeUserFlow(userId);
    }
  }

  continueChangeUserFlow(userId: string) {
    let user = this.b2bSyncService.sellerResponse!.users.find(c => c.id === userId);

    this.patchUserForm(user!);
  }

  patchUserForm(user: ISellerUser) {
    this.userDetailsForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      countryId: user.countryId
    });

    this.statusControl.setValue(user.isActive);

    let userStores = this.usersStoresMap.get(user.id);

    this.userEmail = user.email;

    this.dateCreated = new Date(user.createdAt * 1000);
    this.dateUpdated = new Date(user.modifiedAt * 1000);

    if (userStores!.length > 0) {
      this.selectedStoreControl.setValue(userStores![0].id);

      this.patchContactForm(userStores![0]);
    }

    this.storeControl.setValue(user.details.sellerStores!.map(s => s.store));
    this.storesSelected = this.stores.filter(s => this.storeControl.value!.includes(s.value));

    this.createMode = false;
  }

  patchContactForm(store: UserStore) {
    this.isContact = store.contactFlag;

    if (!store.contactRoleDetails) {
      this.contactForm.patchValue({
        accountingEmail: false,
        carPickupEmail: false,
        documentsEmail: false,
      });
    } else {
      this.contactForm.patchValue({
        accountingEmail: store.contactFlag ? store.contactRoleDetails.accEmailFlag : false,
        carPickupEmail: store.contactFlag ? store.contactRoleDetails.carPickupEmailFlag : false,
        documentsEmail: store.contactFlag ? store.contactRoleDetails.documentEmailFlag : false,
      });
    }
  }

  addUser() {
    if (this.spinner.showProgressBar.value) return;

    if (this.createMode) return this.snackbar.negativeSentiment('You already started to create a new contact!');

    this.createMode = true;

    this.userDropdown.push({ value: 'newUser', viewValue: 'New User' });

    this.userDetailsForm.reset();
    this.storeControl.reset();
    this.selectedStoreControl.reset();
    this.isContact = false;
    this.userEmailControl.reset();

    this.storesSelected = [];

    this.usersStoresMap.set('newUser', []);
    this.userControl.setValue('newUser');

    this.contactForm.reset({
      accountingEmail: false,
      documentsEmail: false,
      carPickupEmail: false
    });
  }

  enterEditMode() {
    this.editMode = true;
    this.userDetailsForm.enable();
    this.contactForm.enable();
    this.selectedStoreControl.enable();
    this.storeControl.enable();

    if (this.userDropdown.length === 0) this.addUser();
  }

  changeUserStatus() {
    let user = this.b2bSyncService.sellerResponse!.users.find(u => u.id === this.userControl.value!)!;

    let stores = user.details.sellerStores.map(s => ({
      id: s.store,
      contactFlag: s.roles.includes(UserRoles.Contact),
      contactRoleDetails: s.contactRoleDetails
    }));

    let body = new EditSellerUserBody(this.sellerId!, user, stores, this.userControl.value!, !user.isActive);

    this.spinner.showProgressBar.next(true);

    this.editUser(body);
  }

  editUser(body: EditSellerUserBody) {
    this.sellerService.editSellerUser(body).subscribe({
      next: resp => {
        let user = this.b2bSyncService.sellerResponse!.users.find(c => c.id === body.id)!;

        user = {
          ...user,
          ...body,
          details: {
            ...user.details,
            sellerStores: body.stores.map(s => ({
              store: s.id,
              roles: s.contactFlag ? ['user', 'contact'] : ['user'],
              contactRoleDetails: s.contactRoleDetails
            }))
          }
        };

        this.statusControl.setValue(body.isActive);

        this.b2bSyncService.sellerResponse!.users[this.b2bSyncService.sellerResponse!.users.findIndex(u => u.id === user.id)] = user;

        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment(resp);
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  changeStoreSelected(storeId: string) {
    let userStores = this.usersStoresMap.get(this.userControl.value!)!;

    userStores[userStores.findIndex(s => s.id === this.selectedStoreControl.value!)] = {
      id: this.selectedStoreControl.value!,
      contactFlag: this.isContact,
      contactRoleDetails: {
        accEmailFlag: this.contactForm.controls.accountingEmail.value!,
        carPickupEmailFlag: this.contactForm.controls.carPickupEmail.value!,
        documentEmailFlag: this.contactForm.controls.documentsEmail.value!,
      }
    };

    this.patchContactForm(userStores.find(s => s.id === storeId)!);
  }

  changeStoresSelected() {
    setTimeout(() => {
      this.storesSelected = this.stores.filter(s => this.storeControl.value!.includes(s.value));

      if (this.selectedStoreControl.value && this.storesSelected.length > 0 && !this.storesSelected.find(s => s.value === this.selectedStoreControl.value!)) {
        this.selectedStoreControl.setValue(this.storesSelected[0].value);

        this.patchContactForm(this.usersStoresMap.get(this.userControl.value!)!.find(s => s.id === this.storesSelected[0].value)!);
      } else if (this.selectedStoreControl.value && !this.storesSelected.find(s => s.value === this.selectedStoreControl.value!)) {
        this.isContact = false;

        this.contactForm.reset({
          accountingEmail: false,
          documentsEmail: false,
          carPickupEmail: false
        });
      }

      let oldStores = this.usersStoresMap.get(this.userControl.value!)!;
      let newStores: UserStore[] = [];

      this.storesSelected.forEach(s => {
        let store = oldStores.find(ss => s.value === ss.id);

        if (store) {
          newStores.push(store);
        } else {
          newStores.push({
            id: s.value,
            contactFlag: false,
            contactRoleDetails: {
              accEmailFlag: false,
              documentEmailFlag: false,
              carPickupEmailFlag: false
            }
          });
        }

        this.usersStoresMap.set(this.userControl.value!, newStores);
      });
    }, 0);
  }
}
