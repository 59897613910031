import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { debounceTime, forkJoin, Subject, takeUntil, tap } from 'rxjs';
import { CarDetails, CarsFilterBody } from 'src/app/core/models/car.model';
import { ISellerResponse } from 'src/app/core/models/seller.model';
import { CarService } from 'src/app/core/services/car.service';
import { InternalShopService } from 'src/app/core/services/internal-shop.service';
import { SellerService } from 'src/app/core/services/seller.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { AppExpansionPanelComponent } from 'src/app/shared/app-expansion-panel/app-expansion-panel.component';
import { Filter, FiltersList } from '../../internal-shop/internal-shop.component';
import { MakeModelFilterComponent } from '../../internal-shop/make-model-filter/make-model-filter.component';
import { SearchCarByIdModalComponent } from './search-car-by-id-modal/search-car-by-id-modal.component';
import { CoreService } from 'src/app/core/services/core.service';
import { CarStatus, VATType } from 'src/app/core/models/info.model';
import { MatRadioChange } from '@angular/material/radio';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'cars-filter',
  templateUrl: './cars-filter.component.html',
  styleUrls: ['./cars-filter.component.scss']
})
export class CarsFilterComponent implements OnInit {
  loading = this.internalShopService.loading;

  private filtersStringEvent: Subject<string> = new Subject();
  public filtersStringEvent$ = this.filtersStringEvent.asObservable();
  public destroyed = new Subject<void>();

  public filters: FiltersList = { fuelType: [], gearbox: [], location: [], bodyType: [], carGroup: [], category: [], sellers: [], carStatus: [] };

  public filtersSelected: Filter[] = [];

  public makeModelFiltersMap: { make: string; models: string[]; }[] = [];
  public makeModelFilters: string[] = [];
  public makeModelFiltersCounter: number = 1;

  cars: CarDetails[] = [];
  sellerResults: ISellerResponse[] = [];

  years: DropdownOption[] = Array.from({ length: (new Date().getFullYear() - 1998) }, (_, i) => i + 2000).reverse().map(y => ({ value: y.toString(), viewValue: y.toString() }));
  yearsFrom: DropdownOption[] = [];
  yearsTo: DropdownOption[] = [];

  manufactureYears = this.fb.group({
    from: new FormControl(),
    to: new FormControl()
  })

  mileageFromControl = new FormControl();
  mileageToControl = new FormControl();

  enginePowFromControl = new FormControl();
  enginePowToControl = new FormControl();

  journeyFromControl = new FormControl();
  journeyToControl = new FormControl();

  vinControl = new FormControl();
  sellerControl = new FormControl();
  vatControl = new FormControl();

  pageNo = 0;

  @ViewChildren('makeModelFilter') components: QueryList<MakeModelFilterComponent> | undefined;
  @ViewChild('sellerPanel') sellerPanel: AppExpansionPanelComponent | undefined;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() isInternalShop = false;

  selectedSeller: ISellerResponse | undefined;

  vatTypes = VATType;

  constructor(private carService: CarService,
    private internalShopService: InternalShopService,
    private fb: FormBuilder,
    private sellerService: SellerService,
    private dialog: MatDialog,
    private utilsService: UtilsService,
    private coreService: CoreService) { }

  ngOnInit(): void {
    this.yearsFrom = this.years;
    this.yearsTo = this.years;

    this.loadInfo();

    this.filtersStringEvent$.pipe(debounceTime(2000),
      tap(() => { }),
      takeUntil(this.destroyed)).subscribe(resp => {
        this.internalShopService.loadingCars.next(true);

        this.pageNo = 0;

        this.carService.getCarFilteredStaff(this.buildSearchBody()).subscribe(resp => {
          this.internalShopService.loadingCars.next(false);

          resp.pageReset = true;

          this.internalShopService.changeCars(resp);
        });
      });


    this.internalShopService.pageChangeEvent$.subscribe(page => {
      this.pageNo = page;

      this.internalShopService.loadingCars.next(true);

      this.carService.getCarFilteredStaff(this.buildSearchBody()).subscribe(resp => {
        this.internalShopService.loadingCars.next(false);

        this.internalShopService.changeCars(resp);

        window.scrollTo(0, 0);
      });
    })


    this.mileageFromControl.valueChanges.pipe(debounceTime(1000), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });

    this.mileageToControl.valueChanges.pipe(debounceTime(1000), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });

    this.enginePowFromControl.valueChanges.pipe(debounceTime(1000), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });

    this.enginePowToControl.valueChanges.pipe(debounceTime(1000), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });

    this.journeyFromControl.valueChanges.pipe(debounceTime(1000), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });
    this.journeyToControl.valueChanges.pipe(debounceTime(1000), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });

    this.vinControl.valueChanges.pipe(debounceTime(1000), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      this.filtersStringEvent.next('');
    });

    this.sellerControl.valueChanges.pipe(debounceTime(1000), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      if (value && value.length > 2) {

        this.sellerService.searchSeller({ name: value, b2bReady: true, details: false }).subscribe((data) => {
          this.sellerResults = data;
        });
      } else {
        this.sellerResults = [];
      }
    });

    // triggers ngOnDestroy on page reload
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();

    sessionStorage.setItem('searchQuery', JSON.stringify(this.buildSearchBody()));
    sessionStorage.setItem('searchQuerySeller', JSON.stringify(this.selectedSeller));
  }

  loadInfo() {
    let body: CarsFilterBody = {
      page: 0,
      itemsPerPage: 20,
    };

    let searchQuerySessionStorage = sessionStorage.getItem('searchQuery');

    if (searchQuerySessionStorage) {
      body = JSON.parse(searchQuerySessionStorage);
    }

    forkJoin({
      makes: this.carService.getCarNomenclatorData('makes'),
      carGroups: this.carService.getCarGroups(),
      carSearch: this.carService.getCarFilteredStaff(body),
      shopTypes: this.carService.getCarShopTypes(),
      fuelType: this.carService.getCarSpecificationData('fuelTypes'),
      gearboxes: this.carService.getCarSpecificationData('gearboxes'),
      bodyTypes: this.carService.getCarSpecificationData('chassis'),
      carStatuses: this.carService.getCarStatuses()
    }).subscribe(resp => {
      this.filters.carGroup = resp.carGroups.map(f => ({ groupValue: 'carGroup', group: 'Group', name: f.name, extraValue: f.id, isChecked: false }));
      this.filters.fuelType = resp.fuelType.map(f => ({ groupValue: 'fuelType', group: 'Fuel Type', name: f.name, isChecked: false }));
      this.filters.location = this.coreService.countries.map(f => ({ groupValue: 'location', group: 'Location', name: f.name, extraValue: f.id, isChecked: false }))
      this.filters.gearbox = resp.gearboxes.map(f => ({ groupValue: 'gearbox', group: 'Gearbox', name: f.name, isChecked: false }));
      this.filters.bodyType = resp.bodyTypes.map(f => ({ groupValue: 'bodyType', group: 'BodyType', name: f.name, isChecked: false }));
      this.filters.carStatus = resp.carStatuses.filter(f => f.status != CarStatus.Sold).map(f => ({ groupValue: 'carStatus', group: 'CarStatus', name: f.status, extraValue: f.id, isChecked: false }));

      this.internalShopService.changeCars(resp.carSearch);

      this.internalShopService.makes = resp.makes.map(data => { return { value: data, viewValue: data } });
      this.internalShopService.cars = resp.carSearch.cars;

      this.filters.category = resp.shopTypes.map(c => ({ groupValue: 'category', group: 'Category', name: c.name, extraValue: c.id, isChecked: false }));

      if (searchQuerySessionStorage) {
        this.loadValues(body);
      }

      this.internalShopService.loading.next(false);
    })
  }

  transformObjectToArray(object: any) {
    let resultArray: { value: any; viewName: string; }[] = [];

    Object.getOwnPropertyNames(object).forEach((val, idx, array) => {
      resultArray.push({ value: object[val], viewName: val });
    });

    return resultArray;
  }

  changeFilter(filter: Filter) {
    this.filtersSelected.includes(filter) ? this.removeFilter(filter) : this.addFilter(filter);
  }

  addFilter(filter: Filter) {
    this.filtersSelected.push(filter);

    this.internalShopService.updateFiltersSelected(this.filtersSelected);
    this.filtersStringEvent.next("filter");
  }

  removeFilter(filter: Filter) {
    switch (filter.groupValue) {
      case 'manufactureYear':
        this.selectManYear(filter.group.split(' ')[1]);
        break;
      case 'seller':
        this.filtersSelected.splice(this.filtersSelected.indexOf(filter), 1)
        this.selectedSeller = undefined;
        break;
      case 'VAT':
        this.filtersSelected.splice(this.filtersSelected.indexOf(filter), 1)
        this.vatControl.reset();
        break;
      default:
        this.filtersSelected.splice(this.filtersSelected.indexOf(filter), 1)

        this.filters[filter.groupValue as keyof typeof this.filters].find(f => f.name === filter.name)!.isChecked = false;

        this.internalShopService.updateFiltersSelected(this.filtersSelected);
        break;
    }

    if (filter.groupValue != 'manufactureYear') this.filtersStringEvent.next("filter");
  }

  modifySelectedMakes() {
    this.internalShopService.selectedMakes = this.components!.toArray().filter(c => c.selectedMake && (this.makeModelFilters.includes(c.filterId) || c.filterId === "firstFilter")).map(c => c.selectedMake);
    this.modifySelectedModels();

    this.internalShopService.changeSelectedMakes();
  }

  addMakeModelFilter() {
    this.makeModelFilters.push(`makeModel-${this.makeModelFiltersCounter++}`);
  }

  removeMakeModelFilter(filterId: string) {
    this.makeModelFilters.splice(this.makeModelFilters.findIndex(m => m === filterId), 1);

    this.modifySelectedMakes();
  }

  modifySelectedModels() {
    let components = this.components!.toArray().filter(c => c.selectedMake && (this.makeModelFilters.includes(c.filterId) || c.filterId === "firstFilter"));

    this.makeModelFiltersMap = [];

    components.forEach(c => {
      if (!c.selectedMake) return;

      if (c.modelControl.value && c.modelControl.value.length > 0) {
        this.makeModelFiltersMap.push({ make: c.selectedMake, models: c.modelControl.value });
      } else {
        this.makeModelFiltersMap.push({ make: c.selectedMake, models: [] });
      }
    })

    this.internalShopService.updateFiltersSelected(this.filtersSelected);
    this.filtersStringEvent.next("filter");
  }

  selectManYear(yearType: string, year?: string) {
    let yearFrom = this.manufactureYears.controls.from.value ? this.manufactureYears.controls.from.value : undefined;
    let yearTo = this.manufactureYears.controls.to.value ? this.manufactureYears.controls.to.value : undefined;

    if (!year) {
      let index = this.filtersSelected.findIndex(f => f.group === `Year ${yearType}`);

      index >= 0 ? this.filtersSelected.splice(index, 1) : 0;

      if (yearType === 'from' && yearTo) {
        this.yearsTo = this.years;

        this.filterYearArray('from', yearTo);
      } else if (yearType === 'to' && yearFrom) {
        this.yearsFrom = this.years;

        this.filterYearArray('to', yearFrom);
      } else {
        this.yearsTo = this.years;
        this.yearsFrom = this.years;
      }

      this.manufactureYears.controls[yearType as keyof typeof this.manufactureYears.controls].reset();
    } else {
      let filter = this.filtersSelected.find(f => f.group === `Year ${yearType}`);

      if (filter) {
        filter.name = year;
      } else {
        this.filtersSelected.push({ groupValue: 'manufactureYear', group: `Year ${yearType}`, name: year, isChecked: false });
      }

      this.filterYearArray(yearType, year);
    }

    this.internalShopService.updateFiltersSelected(this.filtersSelected);
    this.filtersStringEvent.next('');
  }

  filterYearArray(yearType: string, complementYear: string) {
    if (yearType === 'from') {
      this.yearsTo = this.years.slice(0, this.years.findIndex(y => y.value === complementYear) + 1);
    } else {
      this.yearsFrom = this.years.slice(this.years.findIndex(y => y.value === complementYear));
    }
  }

  selectSeller(seller: ISellerResponse) {
    this.selectedSeller = seller;

    let index = this.filtersSelected.findIndex(f => f.groupValue === 'seller');

    if (index < 0) {
      let filter = {
        groupValue: 'seller',
        group: 'Seller',
        name: seller.sellerCompanyDetails.name!,
        isChecked: true,
        extraValue: seller.companyId
      };

      this.filtersSelected.push(filter);
    } else {
      this.filtersSelected[index].name = seller.sellerCompanyDetails.name!;
      this.filtersSelected[index].extraValue = seller.companyId!;
      this.filtersSelected[index].isChecked = true;
    }

    this.internalShopService.updateFiltersSelected(this.filtersSelected);
    this.filtersStringEvent.next('filter');
  }

  resetAllFilters() {
    this.filtersSelected = [];

    this.filters.carGroup = this.filters.carGroup.map(f => ({ ...f, isChecked: false }));
    this.filters.fuelType = this.filters.fuelType.map(f => ({ ...f, isChecked: false }));
    this.filters.gearbox = this.filters.gearbox.map(f => ({ ...f, isChecked: false }));
    this.filters.category = this.filters.category.map(f => ({ ...f, isChecked: false }));
    this.filters.bodyType = this.filters.bodyType.map(f => ({ ...f, isChecked: false }));
    this.filters.location = this.filters.location.map(f => ({ ...f, isChecked: false }));
    this.filters.carStatus = this.filters.carStatus.map(f => ({ ...f, isChecked: false }));

    this.filters.sellers = [];
    this.makeModelFilters = [];
    this.makeModelFiltersMap = [];
    this.internalShopService.selectedMakes = [];

    this.enginePowFromControl.reset();
    this.enginePowToControl.reset();
    this.mileageFromControl.reset();
    this.mileageToControl.reset();
    this.manufactureYears.reset();
    this.vatControl.reset();
    this.journeyFromControl.reset();
    this.journeyToControl.reset();
    this.vinControl.reset();
    this.selectedSeller = undefined;

    this.internalShopService.changeSelectedMakes();

    this.components!.toArray()[0].resetFirstFilter();
    this.internalShopService.updateFiltersSelected(this.filtersSelected);
  }

  openSearchByCarIdModal() {
    this.dialog.open(
      SearchCarByIdModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
      }
    });
  }

  public reloadCars() {
    this.filtersStringEvent.next('');
  }

  selectVATFilter(event: MatRadioChange) {
    let index = this.filtersSelected.findIndex(f => f.group === 'VAT');

    if (index < 0 && event.value != 'None') {
      this.filtersSelected.push({ groupValue: 'VAT', group: 'VAT', name: event.value ? VATType.ExVAT : VATType.InclVAT, isChecked: false });
    } else if (index >= 0 && event.value != 'None') {
      this.filtersSelected[index].name = event.value ? VATType.ExVAT : VATType.InclVAT;
    } else if (index >= 0 && event.value === 'None') {
      this.filtersSelected.splice(index, 1);
    }

    this.internalShopService.updateFiltersSelected(this.filtersSelected);
    this.filtersStringEvent.next('');
  }

  buildSearchBody(): CarsFilterBody {
    let body: CarsFilterBody = {
      page: this.pageNo,
      itemsPerPage: 20,
    };

    if (this.filtersSelected.filter(f => f.groupValue === "gearbox").length > 0) {
      body.gearbox = this.filtersSelected.filter(f => f.groupValue === "gearbox").map(f => f.name);
    }

    if (this.filtersSelected.filter(f => f.groupValue === "fuelType").length > 0) {
      body.fuelType = this.filtersSelected.filter(f => f.groupValue === "fuelType").map(f => f.name);
    }

    if (this.filtersSelected.filter(f => f.groupValue === "bodyType").length > 0) {
      body.bodyType = this.filtersSelected.filter(f => f.groupValue === "bodyType").map(f => f.name);
    }

    if (this.filtersSelected.filter(f => f.groupValue === "category").length > 0) {
      body.category = this.filtersSelected.filter(f => f.groupValue === "category").map(f => f.extraValue!);
    }

    if (this.filtersSelected.filter(f => f.groupValue === "location").length > 0) {
      body.location = this.filtersSelected.filter(f => f.groupValue === "location").map(f => this.coreService.countries.find(c => c.name === f.name)!.id);
    }

    if (this.filtersSelected.filter(f => f.groupValue === "carGroup").length > 0) {
      body.carGroup = this.filtersSelected.filter(f => f.groupValue === "carGroup").map(f => f.extraValue!);
    }

    if (this.filtersSelected.filter(f => f.groupValue === "carStatus").length > 0) {
      body.carStatus = this.filtersSelected.filter(f => f.groupValue === "carStatus").map(f => f.extraValue!);
    }

    if (this.filtersSelected.filter(f => f.groupValue === "seller").length > 0) {
      body.seller = this.filtersSelected.find(f => f.groupValue === "seller")?.extraValue;
    }

    if (this.filtersSelected.find(f => f.groupValue === "VAT")) {
      body.vatStatus = this.filtersSelected.find(f => f.groupValue === "VAT")?.name === this.vatTypes.ExVAT;
    }

    if (this.manufactureYears.controls.from.value || this.manufactureYears.controls.to.value) {
      body.manufactureYear = {
        from: parseInt(this.manufactureYears.controls.from.value) ? parseInt(this.manufactureYears.controls.from.value) : 2000,
        to: parseInt(this.manufactureYears.controls.to.value) ? parseInt(this.manufactureYears.controls.to.value) : parseInt(this.years[0].value)
      }
    }

    if (this.mileageFromControl.value || this.mileageToControl.value) {
      body.mileage = {
        from: this.mileageFromControl.value ? this.mileageFromControl.value : 0,
        to: this.mileageToControl.value ? this.mileageToControl.value : 999999
      }
    }

    if (this.vinControl.value) {
      body.vin = this.vinControl.value;
    }

    if (this.enginePowFromControl.value || this.enginePowToControl.value) {
      body.enginePower = {
        from: this.enginePowFromControl.value ? this.enginePowFromControl.value : 0,
        to: this.enginePowToControl.value ? this.enginePowToControl.value : 999999
      }
    }

    if (this.journeyFromControl.value || this.journeyToControl.value) {
      body.journey = {
        from: this.journeyFromControl.value ? this.utilsService.formatDateValue(this.journeyFromControl.value) : '2000-01-01',
        to: this.journeyToControl.value ? this.utilsService.formatDateValue(this.journeyToControl.value) : `${new Date().getFullYear() + 50}-01-01`
      }
    }

    if (this.makeModelFiltersMap.length > 0) {
      body.makeModel = this.makeModelFiltersMap.map(map => ({
        make: map.make,
        model: map.models[0] ? map.models[0] : ''
      }));
    }

    return body;
  }

  loadValues(body: CarsFilterBody) {
    if (body.carGroup) {
      body.carGroup.forEach(v => {
        let group = this.filters.carGroup.find(f => f.extraValue === v)!;
        group.isChecked = true;

        this.filtersSelected.push(group);
      });
    }

    if (body.carStatus) {
      body.carStatus.forEach(v => {
        let status = this.filters.carStatus.find(f => f.extraValue === v)!;
        status.isChecked = true;

        this.filtersSelected.push(status);
      });
    }

    if (body.category) {
      body.category.forEach(v => {
        let category = this.filters.category.find(f => f.extraValue === v)!;
        category.isChecked = true;

        this.filtersSelected.push(category);
      });
    }

    if (body.location) {
      body.location.forEach(v => {
        let location = this.filters.location.find(f => f.extraValue === v)!;
        location.isChecked = true;

        this.filtersSelected.push(location);
      });
    }

    if (body.seller && sessionStorage.getItem('searchQuerySeller')) {
      let seller = JSON.parse(sessionStorage.getItem('searchQuerySeller')!);

      this.selectedSeller = seller;

      this.filtersSelected.push({
        groupValue: 'seller',
        group: 'Seller',
        name: seller.sellerCompanyDetails.name!,
        isChecked: true,
        extraValue: seller.companyId
      });
    }

    if (body.vatStatus) {
      body.vatStatus.toString() === 'true' ? this.vatControl.setValue(true) : this.vatControl.setValue(false);

      this.filtersSelected.push({ groupValue: 'VAT', group: 'VAT', name: body.vatStatus ? VATType.ExVAT : VATType.InclVAT, isChecked: false });
    }

    if (body.gearbox) {
      body.gearbox.forEach(v => {
        let gearbox = this.filters.gearbox.find(f => f.name === v)!;
        gearbox.isChecked = true;

        this.filtersSelected.push(gearbox);
      });
    }

    if (body.fuelType) {
      body.fuelType.forEach(v => {
        let fuelType = this.filters.fuelType.find(f => f.name === v)!;
        fuelType.isChecked = true;

        this.filtersSelected.push(fuelType);
      })
    }

    if (body.bodyType) {
      body.bodyType.forEach(v => {
        let bodyType = this.filters.bodyType.find(f => f.name === v)!;

        bodyType.isChecked = true;

        this.filtersSelected.push(bodyType)
      });
    }

    if (body.enginePower) {
      this.enginePowFromControl.setValue(body.enginePower.from, { emitEvent: false });

      if (body.enginePower.to < 999999) {
        this.enginePowToControl.setValue(body.enginePower.to, { emitEvent: false });
      }
    }

    if (body.journey) {
      this.journeyFromControl.setValue(body.journey.from, { emitEvent: false });

      this.journeyToControl.setValue(body.journey.to, { emitEvent: false });
    }


    if (body.mileage) {
      this.mileageFromControl.setValue(body.mileage.from, { emitEvent: false });

      if (body.mileage.to < 999999) {
        this.mileageToControl.setValue(body.mileage.to, { emitEvent: false });
      }
    }

    if (body.manufactureYear) {
      this.manufactureYears.controls.from.setValue(body.manufactureYear.from.toString(), { emitEvent: false });
      this.manufactureYears.controls.to.setValue(body.manufactureYear.to.toString(), { emitEvent: false });

      this.filtersSelected.push({ groupValue: 'manufactureYear', group: `Year from`, name: body.manufactureYear.from.toString(), isChecked: false });
      this.filtersSelected.push({ groupValue: 'manufactureYear', group: `Year to`, name: body.manufactureYear.to.toString(), isChecked: false });
    }

    if (body.vin) {
      this.vinControl.setValue(body.vin, { emitEvent: false });
    }


    if (body.makeModel) {
      body.makeModel.forEach((mm, index) => {
        this.internalShopService.selectedMakesModelMap.set(mm.make, mm.model);
        this.makeModelFiltersMap.push({ make: mm.make, models: [mm.model] });

        if (index > 0) {
          this.makeModelFilters.push(`makeModel-${this.makeModelFiltersCounter++}`)
        }
      });

      this.internalShopService.selectedMakes = body.makeModel.map(m => m.make);
    }

    this.pageNo = body.page;

    this.internalShopService.pageNo.next(body.page)

    this.internalShopService.updateFiltersSelected(this.filtersSelected);
  }
}
