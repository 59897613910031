import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/services/settings.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-prod-helper',
  templateUrl: './prod-helper.component.html',
  styleUrls: ['./prod-helper.component.scss']
})


export class ProdHelperComponent implements OnInit {
  kamsUsers: any = [];
  buyers: any = [];
  sellers: any = [];
  bsUsers: any = [];
  kamMatch: any = [];

  constructor(private settingsService: SettingsService) { }

  ngOnInit() {
  }

  async handleKAMUsersFileSelect(evt: Event) {
    const target = evt.target as HTMLInputElement;

    const reader: FileReader = new FileReader();

    if (target.files && target.files.length > 0) {
      reader.readAsBinaryString(target.files![0]);

      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        //, cellDates: true

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws);

        data.forEach((d: any) => {
          let kam: Record<string, any> = {};

          Object.keys(d).forEach((i) => {
            if (i.match('sellerCompanies') || i.match('buyerCompanies')) {
              if (d[i] === 'IGNORE') {
                kam[i as keyof typeof kam] = [];
              } else {
                kam[i as keyof typeof kam] = d[i].split(',').filter((data: any) => data);
              }
            } else {
              kam[i as keyof typeof kam] = d[i];
            }
          });

          this.kamsUsers.push(kam);
        });

        console.log(this.kamsUsers);
      };
    }
  }

  async handleKAMMatchFileSelect(evt: Event) {
    const target = evt.target as HTMLInputElement;

    const reader: FileReader = new FileReader();

    if (target.files && target.files.length > 0) {
      reader.readAsBinaryString(target.files![0]);

      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        //, cellDates: true

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws);

        data.forEach((d: any) => {
          let kam: Record<string, any> = {};

          Object.keys(d).forEach((i) => {
            if (i.match('sellerCompanies') || i.match('buyerCompanies')) {
              if (d[i] === 'IGNORE') {
                kam[i as keyof typeof kam] = [];
              } else {
                kam[i as keyof typeof kam] = d[i].split(',').filter((data: any) => data);
              }
            } else {
              kam[i as keyof typeof kam] = d[i];
            }
          });

          this.kamMatch.push(kam);
        });

        console.log(this.kamMatch);
      };
    }
  }


  async handleBuyerCompanyFileSelect(evt: Event) {
    const target = evt.target as HTMLInputElement;

    const reader: FileReader = new FileReader();

    if (target.files && target.files.length > 0) {
      reader.readAsBinaryString(target.files![0]);

      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        //, cellDates: true

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws);

        data.forEach((d: any) => {
          let buyer = new Object();

          Object.keys(d).forEach((i) => {
            if (["companyMakes", "stores", "storesCity", 'storesAddress', 'storesPostalCode', 'storesMakes',].includes(i)) {
              buyer[i as keyof typeof buyer] = d[i].toString().split(', ').filter((data: any) => data);
            } else if (['storesCarsEst'].includes(i)) {
              buyer[i as keyof typeof buyer] = d[i].toString().split(', ').filter((data: any) => data).map((d: string) => parseInt(d));
            } else {
              buyer[i as keyof typeof buyer] = d[i].toString();
            }
          });

          this.buyers.push(buyer);
        });

        console.log(this.buyers);
      };
    }
  }

  async handleSellerCompanyFileSelect(evt: Event) {
    const target = evt.target as HTMLInputElement;

    const reader: FileReader = new FileReader();

    if (target.files && target.files.length > 0) {
      reader.readAsBinaryString(target.files![0]);

      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        //, cellDates: true

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws);

        data.forEach((d: any) => {
          let seller = new Object();

          Object.keys(d).forEach((i) => {
            if (["sellerCompanies", "buyerCompanies", "companyMakes", "stores", "storesCity", 'storesAddress', 'storesPostalCode', 'companyRepMakes', 'companySoldMakes', 'storesCarsEst', 'storesMakes'].includes(i)) {
              seller[i as keyof typeof seller] = d[i].toString().split(', ').filter((data: any) => data);
            } else {
              seller[i as keyof typeof seller] = d[i].toString();
            }
          });

          this.sellers.push(seller);
        });

        console.log(this.sellers);
      };
    }
  }

  handleFile(evt: Event) {
    const target = evt.target as HTMLInputElement;

    const reader: FileReader = new FileReader();

    if (target.files && target.files.length > 0) {
      reader.readAsBinaryString(target.files![0]);

      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        //, cellDates: true

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const excelData = XLSX.utils.sheet_to_json(ws);

        excelData.forEach((d: any) => {
          let user: Record<string, any> = {};

          Object.keys(d).forEach((i) => {
            if (["buyerStores", "sellerStores"].includes(i)) {
              if (d[i] === 'IGNORE') {
                user[i as keyof typeof user] = [];
              } else {
                user[i as keyof typeof user] = d[i].split(', ').filter((data: any) => data);
              }
            } else {
              user[i as keyof typeof user] = d[i].toString();
            }
          });

          this.bsUsers.push(user);
        });

        console.log(this.bsUsers);
      };
    }
  }

  uploadKAMS() {
    this.settingsService.uploadKAMSBulk(this.kamsUsers).subscribe({
      next: resp => {
        console.log(resp);
      },
      error: err => {
        console.log(err)
      }
    })
  }

  matchKAMS() {
    this.settingsService.matchKAMSBulk(this.kamMatch).subscribe({
      next: resp => {
        console.log(resp);
      },
      error: err => {
        console.log(err)
      }
    })
  }

  uploadSellers() {
    this.settingsService.uploadSellersBulk(this.sellers).subscribe({
      next: resp => {
        console.log(resp);
      },
      error: err => {
        console.log(err)
      }
    })
  }

  uploadBuyers() {
    this.settingsService.uploadBuyersBulk(this.buyers).subscribe({
      next: resp => {
        console.log(resp);
      },
      error: err => {
        console.log(err)
      }
    })
  }

  uploadBuyersSellersUsers() {
    this.settingsService.uploadBuyersSellersUsersBulk(this.bsUsers).subscribe({
      next: resp => {
        console.log(resp);
      },
      error: err => {
        console.log(err)
      }
    })
  }
}
