import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { AddCarGroupModalComponent } from "./car-groups/add-car-group-modal/add-car-group-modal.component";
import { CarGroupsComponent } from "./car-groups/car-groups.component";
import { AddCountryHolidayModalComponent } from "./country-holiday/add-country-holiday-modal/add-country-holiday-modal.component";
import { CountryHolidayComponent } from "./country-holiday/country-holiday.component";
import { DealerMappingComponent } from "./dealer-mapping/dealer-mapping.component";
import { SettingsComponent } from "./settings.component";
import { AddCountryModalComponent } from "./transport-matrix/add-country-modal/add-country-modal.component";
import { TransportMatrixComponent } from "./transport-matrix/transport-matrix.component";
import { CompanyInvoicesComponent } from './company-invoices/company-invoices.component';
import { AddCompanyInvoiceModalComponent } from './company-invoices/add-company-invoice-modal/add-company-invoice-modal.component';
import { ManageCarsInventoryComponent } from './manage-cars-inventory/manage-cars-inventory.component';
import { CreateUsersComponent } from './create-users/create-users.component';
import { VinCheckComponent } from "./vin-check/vin-check.component";
import { ProdHelperComponent } from "./prod-helper/prod-helper.component";


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    SettingsComponent,
    TransportMatrixComponent,
    AddCountryModalComponent,
    DealerMappingComponent,
    CarGroupsComponent,
    CountryHolidayComponent,
    AddCountryHolidayModalComponent,
    AddCarGroupModalComponent,
    CompanyInvoicesComponent,
    AddCompanyInvoiceModalComponent,
    ManageCarsInventoryComponent,
    CreateUsersComponent,
    VinCheckComponent,
    ProdHelperComponent
  ]
})

export class SettingsModule { }
