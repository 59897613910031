<app-navbar></app-navbar>

<app-page-layout class="min-h-[85vh]">
  <div class="flex flex-col gap-9">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">Multiple photos car upload</p>


    </div>

    <app-main-content-layout>
      <div class="title">
        <p>File upload</p>
      </div>

      <div class="grid grid-cols-2 gap-6 p-6">
        <div>
          <div class="bg-white-400 p-4 rounded">
            <div
              class="bg-white-700 rounded border-2 border-dashed border-blue-200 p-5 flex flex-col items-center gap-9 py-9">
              <fa-icon class="text-6xl" [icon]="['fal','up-to-line']"></fa-icon>

              <div class="flex flex-col gap-6 items-center">
                <app-white-btn class="w-fit" (clickEmitter)="selectExcel.click()"
                  btnText="Upload files"></app-white-btn>
              </div>
            </div>

          </div>

          <div class="flex justify-center my-6">
            <primary-btn (clickEmitter)="uploadPhotos()">Import images</primary-btn>
          </div>

          <div class="flex flex-col justify-center gap-6" *ngIf="importResponse.length > 0">
            <p>{{getSuccessfulUploaded() | number}} successfully uploaded</p>

            <p>{{getFailedUploaded() | number}} failed</p>


            <div class="flex justify-center">
              <secondary-btn (clickEmitter)="exportFailedPhotos()">Export failed photos</secondary-btn>
            </div>

            <div class="flex justify-center">
              <secondary-btn (clickEmitter)="startNew()">Finish and start new</secondary-btn>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-6" *ngIf="photos.length > 0">
          <p class="font-bold">{{photos.length | number}} images upload</p>

          <div class="grid grid-cols-2 gap-6">
            <div class="flex flex-col gap-6" *ngFor="let photo of photos | slice: 0: (photos.length /2)">
              <p class="py-3 px-6 rounded bg-white-400">{{photo.name}}</p>
            </div>

            <div class="flex flex-col gap-6" *ngFor="let photo of photos | slice: (photos.length /2): photos.length">
              <p class="py-3 px-6 rounded bg-white-400">{{photo.name}}</p>
            </div>
          </div>
        </div>

        <input class="hidden" [multiple]="true" #selectExcel type="file" accept=".jpg"
          (change)="handleFileSelect($event)">
      </div>
    </app-main-content-layout>
  </div>
</app-page-layout>
