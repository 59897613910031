import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Filter } from "src/app/features/internal-shop/internal-shop.component";
import { DropdownOption } from "src/app/shared/app-dropdown/app-dropdown.component";
import { CarDetails, CarSearchResp } from "../models/car.model";

@Injectable({
  providedIn: 'root'
})

export class InternalShopService {
  loading = new BehaviorSubject(true);
  loadingCars = new BehaviorSubject(false);

  selectedMakes: string[] = [];
  selectedMakesModelMap: Map<string, string> = new Map();

  pageNo = new BehaviorSubject(0);

  makes: DropdownOption[] = [];
  cars: CarDetails[] = [];
  bcCountry = '';

  private makeSubjectEvent: Subject<string> = new Subject<string>();
  public makeSubjectEvent$ = this.makeSubjectEvent.asObservable();

  private carSubjectEvent: Subject<CarSearchResp> = new Subject<CarSearchResp>();
  public carSubjectEvent$ = this.carSubjectEvent.asObservable();

  private filtersSelectedEvent: Subject<Filter[]> = new Subject<Filter[]>();
  public filtersSelectedEvent$ = this.filtersSelectedEvent.asObservable();

  private pageChangeEvent = new Subject<number>();
  public pageChangeEvent$ = this.pageChangeEvent.asObservable();

  private bcCountryEvent = new Subject<string>();
  public bcCountryEvent$ = this.bcCountryEvent.asObservable();

  constructor(private http: HttpClient) {
  }

  changeSelectedMakes() {
    this.makeSubjectEvent.next("");
  }

  changeCars(resp: CarSearchResp) {
    this.carSubjectEvent.next(resp);
  }

  changePage(page: number) {
    this.pageChangeEvent.next(page);
  }

  updateFiltersSelected(filters: Filter[]) {
    this.filtersSelectedEvent.next(filters);
  }

  changeBcCountry(country: string) {
    this.bcCountry = country;
    this.bcCountryEvent.next(country);
  }
}
