<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9" *ngIf="!(loading | async);else placeholder">
    <div class="font-bold text-2xl">
      <p>Search sellers</p>
    </div>

    <div class="grid grid-cols-6 gap-6">
      <app-input [control]="nameControl" id="company-name-filter" class="col-span-1" label="Company name"
        placeholder="Insert company name"></app-input>

      <app-dropdown [control]="countryControl" [options]="countries" [resetValue]="true" id="country-filter"
        class="col-span-1" label="Country" placeholder="Select country"></app-dropdown>

      <app-input [control]="kamControl" id="kam-filter" class="col-span-1" label="KAM"
        placeholder="Insert KAM name"></app-input>

      <app-dropdown [control]="makesSoldControl" [options]="makes" [multipleSelect]="true" id="makes-sold-filter"
        class="col-span-1" label="Makes sold" placeholder="Select makes sold"></app-dropdown>

      <app-dropdown [control]="makesSoldControl" [options]="makes" [multipleSelect]="true" id="makes-represented-filter"
        class="col-span-1" label="Makes represented" placeholder="Select makes represented"></app-dropdown>

      <app-dropdown [control]="statusControl" [options]="statuses" [resetValue]="true" id="onboarding-filter"
        class="col-span-1" label="Onboarding" placeholder="Select onboarding"></app-dropdown>
    </div>

    <div class="flex justify-between">
      <!-- <div class="flex gap-6">
        <button class="text-white text-sm px-6 3xl:px-9 py-3 rounded bg-blue-100 flex gap-3">
          <p>5 sellers to approve</p>
          <fa-icon [icon]="['fal','hexagon-check']"></fa-icon>
        </button>

        <tertiary-btn id="soft-delete" btnText="Soft delete selected"></tertiary-btn>
      </div> -->

      <div class="flex gap-6">
        <secondary-btn id="reset-filters" btnText="Reset filters" (clickEmitter)="resetFilters()"></secondary-btn>

        <primary-btn id="search" btnText="Search" [blueBtn]="true" (clickEmitter)="applyFilters()"></primary-btn>
      </div>
    </div>

    <div class="overflow-x-auto">
      <app-table #sellerTable id="sellers" class="sellers-table" [data]="filteredSellers"
        [displayedColumns]="displayedColumns" [headersMap]="tableColumns" (objectEmitter)="goToSeller($event)"
        [addCellColClass]="true"></app-table>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="grid grid-cols-2 gap-6 gap-y-9">
    <div class="placeholder-animation w-1/4 h-9 col-span-1"></div>

    <div class="col-span-2 grid grid-cols-6 gap-6">
      <div class="placeholder-animation h-9 col-span-1" *ngFor="let i of [].constructor(6)"></div>
    </div>

    <div class="placeholder-animation w-1/4 h-9 col-span-1"></div>
    <div class="placeholder-animation w-1/4 h-9 col-span-1 place-self-end"></div>

    <div class="flex flex-col gap-2 col-span-2">
      <div class="bg-white-100 h-8"></div>
      <div class="placeholder-animation h-6" *ngFor="let i of [].constructor(5)"></div>
    </div>
  </div>
</ng-template>
