import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SellerStoreDTO } from 'src/app/core/models/seller-models/seller-create.model';
import { B2BSellerSyncService } from 'src/app/core/services/b2b-seller-sync.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import * as uuid from 'uuid';

@Component({
  selector: 'app-seller-stores',
  templateUrl: './seller-stores.component.html',
  styleUrls: ['./seller-stores.component.scss']
})
export class SellerStoresComponent implements OnInit {
  createMode: boolean = false;
  sellerId = this.b2bService.sellerId;

  saveFormSubscription: Subscription = new Subscription();
  routeSubscription: Subscription = new Subscription();

  makes = this.b2bService.makes;

  storeDetailsForm = this.fb.group({
    tempId: new FormControl<string | undefined>(undefined),
    name: ['', Validators.required],
    city: ['', Validators.required],
    address: ['', Validators.required],
    postalCode: ['', Validators.required],
    countryBank: [false, Validators.required],
    makesRep: [[''], Validators.required],
    makesSold: [[''], Validators.required],
  });

  storesDropdown: DropdownOption[] = this.b2bService.sellerStores.map(s => ({ value: s.tempId!, viewValue: s.name! }));

  companyCountry = this.coreService.countries.find(c => c.id === this.b2bService.seller!.countryId)!;

  dateCreated = new Date();
  statusControl = new FormControl({ value: true, disabled: true });

  stores: SellerStoreDTO[] = JSON.parse(JSON.stringify(this.b2bService.sellerStores));

  public storeToggleControl: FormControl = new FormControl();

  constructor(private fb: FormBuilder,
    private coreService: CoreService,
    private snackbar: SnackbarService,
    private b2bService: B2BSellerSyncService) { }

  ngOnInit() {
    this.routeSubscription = this.b2bService.isRouteChangeEvent$.subscribe(
      async (value) => {
        if (this.b2bService.sellerStores.length === 0 && value != 'company') {
          this.snackbar.negativeSentiment('No stores saved');

          return;
        } else if (this.storeDetailsForm.invalid) {
          let goNext = await this.coreService.openUnsavedChangesModal();

          goNext ? this.b2bService.setCurrentTab(value) : 0;
        } else {
          this.b2bService.setCurrentTab(value);
        }
      }
    )

    this.saveFormSubscription = this.b2bService.saveFormEvent$.subscribe((value) => {
      if (this.storeDetailsForm.invalid) {
        this.snackbar.negativeSentiment('You need to complete all required fields');
        return;
      }

      this.saveStore();
      this.b2bService.setCurrentTab('users');
    });

    if (this.stores.length > 0) {
      this.storeToggleControl.setValue(this.stores[0].tempId);

      this.patchForm(this.stores[0]);
    } else {
      this.addNewStore();
    }
  }

  ngOnDestroy() {
    this.saveFormSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  saveStore() {
    if (this.storeDetailsForm.invalid) {
      this.snackbar.negativeSentiment('You need to complete all required fields');
      return;
    }

    let store = new SellerStoreDTO(this.storeDetailsForm.value, this.companyCountry.id);

    if (this.stores.find(s => s.tempId === store.tempId)) {
      this.stores[this.stores.findIndex(s => s.tempId === store.tempId)] = store;
    } else {
      store.tempId = uuid.v4();
      this.stores.push(store);
    }

    this.b2bService.sellerStores = this.stores;
    this.storesDropdown = this.b2bService.sellerStores.map(s => ({ value: s.tempId!, viewValue: s.name! }));

    this.createMode = false;

    this.storeToggleControl.setValue(store.tempId);

    this.snackbar.positiveSentiment("Store saved!");
  }

  async changeStore(storeId: string) {
    if (this.createMode) {
      const currentStoreId = this.storeToggleControl.value;

      let result = await this.coreService.openUnsavedChangesModal();

      if (result) {
        this.stores.splice(this.stores.findIndex(s => s.tempId === currentStoreId), 1);

        let store = this.stores.find(s => s.tempId === storeId)!;
        this.storesDropdown = this.stores.map(s => ({ value: s.tempId!, viewValue: s.name! }));

        this.patchForm(store);
      } else {
        this.storeToggleControl.setValue(currentStoreId);
      }
    } else {
      let store = this.stores.find(s => s.tempId === storeId)!;

      this.patchForm(store);
    }
  }

  addNewStore() {
    if (this.createMode) {
      this.snackbar.negativeSentiment("You already started to create a new store");
      return;
    }

    const tempId = uuid.v4();

    this.stores.push(new SellerStoreDTO({ tempId: tempId, name: 'New Store' }, this.companyCountry.id));
    this.storesDropdown.push({ value: tempId, viewValue: 'New Store' });
    this.storeToggleControl.setValue(tempId);
    this.storeDetailsForm.reset({
      countryBank: false,
      tempId: tempId
    });
    this.createMode = true;
  }

  copyDataFromCompany() {
    if (this.b2bService.seller) {
      this.storeDetailsForm.patchValue({
        name: this.b2bService.seller.name,
        address: this.b2bService.seller.address,
        city: this.b2bService.seller.city,
        postalCode: this.b2bService.seller.postalCode,
      })
    } else {
      this.snackbar.negativeSentiment('No company saved!');
    }
  }

  copyMakes() {
    if (this.b2bService.seller) {
      this.storeDetailsForm.patchValue({
        makesRep: this.b2bService.seller.repMakes,
        makesSold: this.b2bService.seller.soldMakes,
      })
    } else {
      this.snackbar.negativeSentiment('No company saved!');
    }
  }

  patchForm(store: SellerStoreDTO) {
    this.storeDetailsForm.patchValue({
      name: store.name,
      address: store.address,
      city: store.city,
      postalCode: store.postalCode,
      countryBank: store.countryBank,
      makesRep: store.repMakes,
      makesSold: store.soldMakes
    });

    this.storeDetailsForm.controls.tempId.setValue(store.tempId);
  }
}
