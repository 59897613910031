import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardMaintenance } from './core/guards/auth.guard';
import { CarViewComponent } from './features/car-view/car-view.component';
import { CarsOnListImportComponent } from './features/cars-inventory/cars-on-list-import/cars-on-list-import.component';
import { CarsOnListViewComponent } from './features/cars-inventory/cars-on-list/cars-on-list-view/cars-on-list-view.component';
import { CarsOnListComponent } from './features/cars-inventory/cars-on-list/cars-on-list.component';
import { ManageCarsComponent } from './features/cars-inventory/manage-cars/manage-cars.component';
import { OnlineListUploadComponent } from './features/cars-inventory/online-list-upload/online-list-upload.component';
import { CarViewPageComponent } from './features/internal-shop/car-view-page/car-view-page.component';
import { InternalShopComponent } from './features/internal-shop/internal-shop.component';
import { LoginComponent } from './features/login/login.component';
import { OrdersComponent } from './features/orders/order-create/orders.component';
import { OrderProcessComponent } from './features/orders/order-process/order-process.component';
import { OrdersViewComponent } from './features/orders/orders-view/orders-view.component';
import { B2bSellerComponent } from './features/seller/b2b-seller/b2b-seller.component';
import { OnlineSellerComponent } from './features/seller/online-seller/online-seller.component';
import { ViewSellersComponent } from './features/seller/view-sellers/view-sellers.component';
import { SettingsComponent } from './features/settings/settings.component';
import { CreateOfferComponent } from './features/offers/create-offer/create-offer.component';
import { ViewOffersComponent } from './features/offers/view-offers/view-offers.component';
import { CreateBuyerComponent } from './features/buyer/create-buyer/create-buyer.component';
import { ViewBuyerComponent } from './features/buyer/view-buyer/view-buyer.component';
import { ViewB2bSellerComponent } from './features/seller/view-b2b-seller/view-b2b-seller.component';
import { OrderViewKamComponent } from './features/orders/order-view-kam/order-view-kam.component';
import { RequestCarComponent } from './features/request-car/request-car/request-car.component';
import { ViewCarRequestsComponent } from './features/request-car/view-car-requests/view-car-requests.component';
import { ViewBuyersComponent } from './features/buyer/view-buyers/view-buyers.component';
import { OrderPendingProcessComponent } from './features/orders/order-pending-process/order-pending-process.component';
import { ViewSellerOffersComponent } from './features/sell-your-car/view-seller-offers/view-seller-offers.component';
import { SellerOfferComponent } from './features/sell-your-car/seller-offer/seller-offer.component';
import { RestrictedPageComponent } from './features/restricted-page/restricted-page.component';
import { CarManualUploadComponent } from './features/cars-inventory/car-manual-upload/car-manual-upload.component';
import { HomePageComponent } from './features/home-page/home-page.component';
import { OrdersCarsComponent } from './features/orders/orders-cars/orders-cars.component';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { MultiplePhotoCarUploadComponent } from './features/cars-inventory/multiple-photo-car-upload/multiple-photo-car-upload.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomePageComponent },
  { path: 'after-login', component: LoginComponent },
  { path: 'restricted', component: RestrictedPageComponent },
  {
    path: '', canActivate: [AuthGuardMaintenance], children: [
      { path: 'buyers', component: ViewBuyersComponent },
      { path: 'online-car-upload', component: CarManualUploadComponent },
      { path: 'car/:page/:car-id', component: CarViewComponent },
      { path: 'buyer/:page', component: CreateBuyerComponent },
      { path: 'buyer/:page/:buyerId', component: ViewBuyerComponent },
      { path: 'cars-on-list/:list', component: CarsOnListViewComponent },
      { path: 'cars-on-list', component: CarsOnListComponent },
      { path: 'online-list-upload', component: OnlineListUploadComponent },
      { path: 'multiple-photos-car-upload', component: MultiplePhotoCarUploadComponent },
      { path: 'cars-on-list-import/:page', component: CarsOnListImportComponent },
      { path: 'manage-cars', component: ManageCarsComponent },
      { path: 'shop', component: InternalShopComponent },
      { path: 'shop/car/:carId', component: CarViewPageComponent },
      { path: 'settings/:page', component: SettingsComponent },
      { path: 'seller/online-seller/:sellerId', component: OnlineSellerComponent },
      { path: 'seller/online-seller', component: OnlineSellerComponent },
      { path: 'create-seller', component: B2bSellerComponent },
      { path: 'seller/:page/:sellerId', component: ViewB2bSellerComponent },
      { path: 'sellers', component: ViewSellersComponent },
      { path: 'create-order', component: OrdersComponent },
      { path: 'create-order/:orderId', component: OrdersComponent },
      { path: 'orders', component: OrdersViewComponent },
      { path: 'orders-cars', component: OrdersCarsComponent },
      { path: 'order/:orderId', component: OrderProcessComponent },
      { path: 'process-order/:orderId', component: OrderProcessComponent },
      { path: 'order-pending-process/:orderId', component: OrderPendingProcessComponent },
      { path: 'order-kam-view/:orderId', component: OrderViewKamComponent },
      { path: 'create-offer', component: CreateOfferComponent },
      { path: 'view-offers', component: ViewOffersComponent },
      { path: 'request-car', component: RequestCarComponent },
      { path: 'car-requests', component: ViewCarRequestsComponent },
      { path: 'sellers-offers', component: ViewSellerOffersComponent },
      { path: 'seller-offer/:offerId', component: SellerOfferComponent },
      { path: 'logout', component: LoginComponent },
    ],
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
