<app-navbar></app-navbar>

<app-page-layout>
  <div *ngIf="!(loading | async);else placeholder">
    <div class="page-title">
      <p>Search Buyers</p>
    </div>

    <div class="flex flex-col gap-9">
      <div [hidden]="buyers.length === 0" class="flex flex-col gap-9">
        <div class="flex gap-6 flex-wrap items-center">
          <app-input [control]="filterControl.controls.vat" label="VAT" placeholder="Insert VAT"></app-input>

          <app-input [control]="filterControl.controls.name" label="Company name"
            placeholder="Insert company name"></app-input>

          <app-input [control]="filterControl.controls.kam" label="KAM username"
            placeholder="Insert KAM username"></app-input>

          <app-dropdown id="country" [control]="filterControl.controls.country" label="Country" [resetValue]="true"
            [options]="countries" class="col-span-1" placeholder="Select country"></app-dropdown>

          <app-dropdown id="makes" [control]="filterControl.controls.makes" label="Makes" [options]="makes"
            [multipleSelect]="true" class="col-span-1" placeholder="Select makes" [selectAll]="true">
          </app-dropdown>

          <app-dropdown id="priority" [control]="filterControl.controls.priority" label="Priority" [resetValue]="true"
            [options]="priorities" class="col-span-1" placeholder="Select priority"></app-dropdown>

          <app-dropdown id="status" [control]="filterControl.controls.status" label="Status" [resetValue]="true"
            [options]="statuses" class="col-span-1" placeholder="Select status"></app-dropdown>

          <app-input id="email-filter" placeholder="Insert user email" label="User email"
            [control]="filterControl.controls.username"></app-input>
        </div>

        <div class="flex justify-between">
          <div class="flex items-center gap-6">
            <!-- <tertiary-btn id="export-xls" btnText="Export .xls"></tertiary-btn> -->

            <tertiary-btn *ngIf="isAdmin" id="add-buyer" (clickEmitter)="goToCompanyView()"
              btnText="Add new buyer"></tertiary-btn>

            <p class="text-sm text-white-600">{{results}} result available</p>
          </div>

          <div class="flex gap-6">
            <secondary-btn id="reset-filters" btnText="Reset filters" (clickEmitter)="resetFilters()"></secondary-btn>

            <primary-btn id="search" btnText="Search" [blueBtn]="true"
              (clickEmitter)="applyFilters(true)"></primary-btn>
          </div>
        </div>

        <div *ngIf="!(loadingTable | async);else tablePlaceholder">
          <app-table class="buyers-table" #buyersTable [data]="buyers" [displayedColumns]="displayedColumns"
            [headersMap]="tableColumns" (objectEmitter)="goToBuyer($event)" [addCellColClass]="true"
            [hidePaginator]="true">
          </app-table>
        </div>

        <mat-paginator #paginator [length]="results" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
          aria-label="Select page" (page)="applyFilters()">
        </mat-paginator>
      </div>


      <ng-template #noResults>
        <div *ngIf="buyers.length === 0" class="flex flex-col gap-4">
          <div class="flex max-w-lg mx-auto">
            <img src="./assets/background-photos/no-table-results.png" alt="">
          </div>
          <div class="text-center">
            <p class="text-2xl">Start by creating a buyer.</p>
          </div>
          <div class="flex justify-center">
            <tertiary-btn (clickEmitter)="goToCompanyView()" btnText="Create new buyer"></tertiary-btn>
          </div>
        </div>

        <div *ngIf="buyers.length > 0" class="flex flex-col gap-4">
          <div class="flex max-w-lg mx-auto">
            <img class="relative -left-12" src="./assets/background-photos/no-results.svg" alt="">
          </div>
          <div class="text-center">
            <p class="text-2xl">No results available.</p>
            <p class="text-sm text-white-600 mt-3">Try to refine your filters or reset them.</p>
          </div>
          <div class="flex justify-center">
            <tertiary-btn (clickEmitter)="resetFilters()" btnText="Reset filters"></tertiary-btn>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</app-page-layout>

<ng-template #placeholder>
  <div class="grid grid-cols-2 gap-6 gap-y-9">
    <div class="placeholder-animation w-1/4 h-9 col-span-1"></div>

    <div class="placeholder-animation h-9 col-span-2"></div>

    <div class="placeholder-animation w-1/4 h-9 col-span-1"></div>
    <div class="placeholder-animation w-1/4 h-9 col-span-1 place-self-end"></div>

    <app-table-placeholder class="col-span-2"></app-table-placeholder>
  </div>
</ng-template>

<ng-template #tablePlaceholder>
  <app-table-placeholder class="col-span-2"></app-table-placeholder>
</ng-template>
