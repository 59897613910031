<app-navbar></app-navbar>

<app-page-layout>
  <div class="flex flex-col gap-9">
    <div class="flex justify-between">
      <p class="font-bold text-2xl">View offers</p>
    </div>

    <div class="flex flex-wrap gap-6">
      <app-input label="Seller name" placeholder="Insert seller name" [control]="sellerNameSearchControl"></app-input>

      <app-dropdown label="Country" placeholder="Select country" [control]="countryControl" [options]="countries"
        [resetValue]="true"></app-dropdown>

      <app-dropdown label="Status" placeholder="Select status" [control]="statusControl" [options]="statuses"
        [resetValue]="true"></app-dropdown>

      <app-blue-btn (clickEmitter)="searchOffers(true)">Search</app-blue-btn>
    </div>

    <!-- offer table -->
    <div class="offer-table overflow-x-auto" *ngIf="!(loading | async) && !(loadingTable | async);else placeholder">
      <table mat-table [dataSource]="offers" multiTemplateDataRows
        class="mat-elevation-z8 w-full shadow-none border border-white-500 whitespace-nowrap">
        <ng-container [matColumnDef]="column.value" *ngFor="let column of headers">
          <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            {{column.tableView}}
          </th>

          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <p [ngClass]="'cell-value-' + getValue(element, column) ">
              {{getValue(element, column)}}
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row"
              (click)="(offerSelected = offerSelected === element ? null : element); $event.stopPropagation()">
              <mat-icon *ngIf="offerSelected !== element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="offerSelected === element">keyboard_arrow_up</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Expanded offer column -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="offer-element-detail" [@detailExpand]="element == offerSelected ? 'expanded' : 'collapsed'">

              <!-- offer selected content -->
              <div class="grid grid-cols-2 gap-6 rounded bg-white p-9" *ngIf="offerSelected">
                <div class="2xl:flex 2xl:gap-6">
                  <a [href]="window.location.origin + '/car/car-details/' + offerSelected.carDetails.carMainInfoId"
                    class="2xl:w-72 3xl:w-[32rem] flex h-64 2xl:block 3xl:flex">
                    <img *ngIf="offerSelected.carDetails.profilePhoto != 'None';else noPhoto" class="object-fit"
                      [src]="offerSelected.carDetails.profilePhoto" alt="">
                  </a>

                  <ng-template #noPhoto>
                    <img class=" object-fit" src="./assets/background-photos/car-placeholder.png" alt="">
                  </ng-template>

                  <div class="flex flex-col gap-6">
                    <div class="font-bold text-xl mt-6 2xl:mt-0">
                      <a [href]="window.location.origin + '/car/car-details/' + offerSelected.carDetails.carMainInfoId">{{offerSelected.carDetails.make}}
                        {{offerSelected.carDetails.model}}, {{offerSelected.carDetails.variant}}</a>
                    </div>

                    <div class="flex flex-wrap gap-3">
                      <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
                        {{offerSelected.carDetails.manufactureYear}}
                      </div>
                      <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
                        {{offerSelected.carDetails.mileage | number}}km
                      </div>
                      <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
                        {{offerSelected.carDetails.fuelType}}
                      </div>
                      <div class="bg-white-100 border border-white-500 rounded py-2 px-3">
                        {{offerSelected.carDetails.gearbox}}
                      </div>
                    </div>

                    <p *ngIf="offerSelected.carDetails.vin && offerSelected.carDetails.vin.length > 0">VIN:
                      {{offerSelected.carDetails.vin}}</p>

                    <p *ngIf="offerSelected.carDetails.regNo && offerSelected.carDetails.regNo.length > 0">Registration
                      number:
                      {{offerSelected.carDetails.regNo}}</p>

                    <div class="flex justify-between">
                      <div class="flex gap-3">
                        <fa-icon [icon]="['fal','cube']"></fa-icon>
                        <p>Location: <span class="font-bold">{{offerSelected.carDetails.locationName}}</span></p>
                      </div>

                      <div class="flex gap-3">
                        <fa-icon [icon]="['fal','share-nodes']"></fa-icon>
                        <p>Source: <span class="text-blue-500">{{offerSelected.carDetails.sourceName}}</span></p>
                      </div>
                    </div>

                    <app-blue-btn class="w-fit" id="get-bc" (clickEmitter)="getCarBC()">Get business case</app-blue-btn>

                    <div *ngIf="bc">
                      <div class="font-bold">
                        <p>Business case for {{offerSelected.buyerLocation}}:</p>
                      </div>

                      <div class="grid grid-cols-2 gap-5 mt-7 mb-16">
                        <div class="col-span-1 flex gap-3">
                          <fa-icon [icon]="['fal','check']"></fa-icon>
                          <p>Retail price: €{{bc.spotPrice | number}}</p>
                        </div>

                        <div class="col-span-1 flex gap-3">
                          <fa-icon [icon]="['fal','check']"></fa-icon>
                          <p>Monthly sold: {{bc.carsSold30Days | number}}</p>
                        </div>

                        <div class="col-span-1 flex gap-3">
                          <fa-icon [icon]="['fal','check']"></fa-icon>
                          <p>
                            Sales factor: {{!isNaN(bc.salesFactor) ? (bc.salesFactor | number) : 'No data'}}
                          </p>
                        </div>

                        <div class="col-span-1 flex gap-3 text-green-200"
                          [ngClass]="{'!text-red': (bc.spotPrice - offerSelected.latestOffer! < 0)}">
                          <fa-icon [icon]="['fal','check']"></fa-icon>
                          <p class="font-bold">
                            Est Profit: €{{bc.spotPrice - offerSelected.latestOffer! | number : '1.0-2'}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-6">
                  <div class="flex gap-12 pb-9 border-b border-white-500">
                    <div>
                      <p class="text-white-800">Online sale price</p>
                      <p class="text-2xl font-bold">€{{offerSelected.carDetails.shopPrice | number}} <span
                          class="text-white-800 font-normal text-sm">{{offerSelected.carDetails.vatStatus ?
                          vatStatus.ExVAT :
                          vatStatus.InclVAT}}</span></p>
                    </div>
                    <div class="text-white-800">
                      <p>Minimum selling price</p>
                      <p class="text-2xl font-bold">€{{offerSelected.carDetails.minSellingPrice | number}} <span
                          class="font-normal text-sm">{{offerSelected.carDetails.vatStatus ? vatStatus.ExVAT :
                          vatStatus.InclVAT}}</span></p>
                    </div>
                  </div>

                  <div class="grid grid-cols-2 gap-y-96 py-9 border-b border-white-500">
                    <div class="">
                      <p class="text-white-800">Latest offer sent</p>
                      <p class="text-2xl font-bold text-blue-500">€{{offerSelected.latestOffer | number}} <span
                          class="text-white-800 font-normal text-sm">{{offerSelected.carDetails.vatStatus ?
                          vatStatus.ExVAT :
                          vatStatus.InclVAT}}</span></p>
                    </div>

                    <div class="flex flex-wrap gap-6 justify-end items-center" *ngIf="showOfferButtons()">
                      <primary-btn id="decline-offer" [blueBtn]="true" (clickEmitter)="declineOffer()">Decline
                        offer</primary-btn>

                      <primary-btn id="accept-offer" (clickEmitter)="acceptOffer()">Accept offer</primary-btn>
                    </div>
                  </div>


                  <div class="flex flex-col gap-6 pt-9">
                    <div class="flex justify-between items-center">
                      <div [ngSwitch]="offerSelected.offerStatus">
                        <p *ngSwitchCase="offerStatus.Rejected">Offer rejected</p>

                        <p *ngSwitchCase="offerStatus.Accepted">Offer accepted</p>

                        <p *ngSwitchCase="offerStatus.Canceled">Offer canceled</p>

                        <p *ngSwitchCase="offerStatus.Expired">Offer expired</p>

                        <p *ngSwitchCase="offerStatus.Pending">Offer expires: {{offerSelected.expiresOn | date:
                          'dd/MM/yyyy'}}
                        </p>
                      </div>

                      <div class="flex gap-6" *ngIf="offerSelected.offerStatus != offerStatus.Accepted">
                        <secondary-btn id="open-counter-offer-modal" (clickEmitter)="openCounterOfferModal()">Make
                          counter
                          offer</secondary-btn>

                        <secondary-btn id="cancel-offer" (clickEmitter)="cancelOffer()" *ngIf="isCreatedByUser()">Cancel
                          offer</secondary-btn>
                      </div>
                    </div>

                    <div class="flex flex-col gap-3">
                      <p class="text-sm text-white-500">Offer history</p>

                      <div class="bg-white-700 text-sm flex gap-3 p-3 rounded w-full"
                        *ngFor="let log of offerSelected.logs; let first = first; let even = even; let i = index"
                        [ngClass]="{'!bg-white-100' : first,
                          'bg-white-300' : even}">

                        <p class="text-white-800">{{log.split(' on ')[0]}} on <span class="text-blue-100">{{
                            getLogDate(log)| date
                            :'dd/MM/yyy, HH:mm'}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100"></tr>

        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="offer-element-row"
          [class.car-expanded-row]="offerSelected === element"
          (click)="offerSelected = offerSelected === element ? null : element">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="offer-detail-row"></tr>
      </table>
    </div>

    <mat-paginator #paginator [length]="results" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" aria-label="Select page"
      (page)="searchOffers()">
    </mat-paginator>


  </div>
</app-page-layout>

<ng-template #placeholder>
  <app-table-placeholder></app-table-placeholder>
</ng-template>
